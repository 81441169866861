import { IBaseProps } from "@architecture-innovation-transformation/lib-common";
import { Button, Drawer, Icons, Table, Typography, useTable } from "@pankod/refine-antd";
import { CrudFilter, useGetIdentity, useOne } from "@pankod/refine-core";
import type { ColumnsType } from 'antd/es/table';
import { StateButtons } from "components/common";
import { GetListTableColumns, mergePreferences } from "components/utils/getListTableColumns";
import { IEntityListColumns, IUser } from "interfaces";
import { IDemand, IDemandMetadata } from "interfaces/resourcing";
import { useEffect, useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH } from "scripts/site";
import { ManagePosition } from "./managePosition";

export declare type PositionFilterProps = {
    requestId: string,
    requestor: string
};

export const PositionList: React.FC<PositionFilterProps> = ({ requestId, requestor }) => {

    const { data: userData } = useGetIdentity();
    const userObj = userData as IUser;
    const [reviewFormOpen, switchReviewForm] = useState(false);
    const [refetchData, switchRefetchFlag] = useState(false);
    const [currentPosition, setCurrentPosition] = useState<IDemand>();
    const [resourcingAdmin, setResourcingAdmin] = useState(false);
    const [isRequestor, setIsRequestor] = useState(false);
    const [tableColumns, setTableColumns] = useState<IEntityListColumns>();
    const columnPreference = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.POSITION)?.columns;

    const onFormSubmit = () => {
        switchReviewForm(false);
    };

    const refetchSuggestionsData = () => {
        switchRefetchFlag(true);
    }

    const positionFilter: CrudFilter[] = requestId ? [{
        field: "resourcingRequestID",
        operator: "eq",
        value: requestId
    }] : [];

    const { tableProps, sorter, tableQueryResult } = useTable<IDemand>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.POSITION,
        permanentFilter: positionFilter,
        hasPagination: false
    });

    const { data: metadata } = useOne<IDemandMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.POSITION,
        queryOptions: {
            enabled: true,
            staleTime: 300000
        }
    });

    const metaConfig = metadata?.data?.config;

    useEffect(() => {
        if (refetchData) {
            tableQueryResult.refetch();
            switchRefetchFlag(false);
        }
        setResourcingAdmin((["resourcingAdmin", "resourcingTeamMember"]).some(r => userObj?.entityRoleIds.some(ri => r === ri)));
        setIsRequestor(userObj?.id === requestor);
    }, [refetchData, tableQueryResult, requestor, userObj?.id, userObj?.entityRoleIds]);

    useEffect(() => {
        if (metaConfig) {
            setTableColumns(mergePreferences(metaConfig.listColumns, columnPreference, RESOURCE_PATH.POSITION));
        }
    }, [metaConfig, columnPreference]);

    const getTableColumns = (): ColumnsType<IDemand> => {
        const columns: ColumnsType<IDemand> = GetListTableColumns(tableColumns, sorter, RESOURCE_PATH.POSITION);
        if ((resourcingAdmin || isRequestor)) {
            columns.push(
                {
                    title: "Actions",
                    dataIndex: "actions",
                    render: (_, record) => (
                        <>
                            {metaConfig && metaConfig.editableStates.includes(record.stateManager.state) &&
                                <>
                                    {resourcingAdmin &&
                                        <Button
                                            type="default"
                                            key="help"
                                            onClick={() => {
                                                setCurrentPosition(record);
                                                switchReviewForm(true);
                                            }}
                                            icon={<Icons.AimOutlined />}>Manage</Button>
                                    }
                                    {isRequestor &&
                                        <StateButtons {...record as IBaseProps}
                                            resourceToAct={RESOURCE_PATH.POSITION}
                                            resourceToActId={record?.id || "0"}
                                            resourceToNavigate={RESOURCE_PATH.RESOURCINGREQUEST}
                                            navigateToId={record?.resourcingRequestID}
                                            hideActions={['open', 'hold', 'mapped', 'cancel', 'lost', 'closed', 'rescope']}
                                            skipAssignedToCheck={true}
                                        />
                                    }
                                </>
                            }
                        </>
                    )
                }
            );
        }
        return columns
    }

    return (
        <>
            <Table {...tableProps}
                rowKey="id"
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
                }}
                columns={getTableColumns()}
            />
            <Drawer
                title="Manage Position"
                placement="right"
                size="large"
                open={reviewFormOpen}
                onClose={() => { switchReviewForm(false) }}
            >
                {currentPosition && metadata?.data &&
                    <ManagePosition position={currentPosition} positionMetadata={metadata.data} onFormSubmission={onFormSubmit} refetch={refetchSuggestionsData} />
                }
            </Drawer>
        </>
    );
};