import { Button, CreateButton, Drawer, Icons, List, Table, Tag, Typography, useTable } from "@pankod/refine-antd";
import { CrudFilters, CrudSorting, HttpError, IResourceComponentsProps, useGetIdentity, useOne, useUpdate } from "@pankod/refine-core";
import type { ColumnsType } from 'antd/es/table';
import { RenderMDFile } from "components/common";
import { ShowFilterIndicator } from "components/common/showFilterIndicator";
import { RenderWorkflowModal } from "components/common/workflowModal";
import { mapFiltersToFormFields } from "components/resourcing/mapFilters";
import { RequestFilter } from "components/resourcing/requestFilter";
import { generateCrudFilters, generatePageSize, generateSortOrder } from "components/utils/generateCrudFilters";
import { GetListTableColumns, mergePreferences, preferencesData } from "components/utils/getListTableColumns";
import { ListColumnPicker } from "components/utils/listColumnPicker";
import { IBaseProps, IEntityListColumns, IState, IUser, IUserPreferences } from "interfaces";
import { IResourcingRequest, IResourcingRequestFilterVariables, IResourcingRequestMetadata } from "interfaces/resourcing";
import { useEffect, useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, MY_PROFILE, RESOURCE_PATH, removeLocalSessionStorage } from "scripts/site";
import { RequestExpand } from "./requestExpand";

export const ResourcingRequestList: React.FC<IResourceComponentsProps> = () => {
    const { data: userData } = useGetIdentity();
    const userObj = userData as IUser;

    const resourcingFilter: CrudFilters = userObj?.entityRoleIds.includes("resourcingTeamMember") ? [
        {
            field: "stateManager.state",
            operator: "ne",
            value: "draft"
        }
    ] : [];

    let savedFilters = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.RESOURCINGREQUEST)?.filters || [];
    let savedSortOrder = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.RESOURCINGREQUEST)?.sortOrder;
    let savedPageSize = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.RESOURCINGREQUEST)?.pageSize || 10;
    let initialSortOrder: CrudSorting = (savedSortOrder && savedSortOrder.length > 0) ? savedSortOrder : [{
        field: "updatedAt",
        order: "desc"
    }];

    const { tableProps, tableQueryResult, searchFormProps, sorter, setSorter, setPageSize } = useTable<IResourcingRequest, HttpError, IResourcingRequestFilterVariables>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.RESOURCINGREQUEST,
        initialSorter: initialSortOrder,
        initialFilter: savedFilters,
        initialPageSize: savedPageSize,
        permanentFilter: resourcingFilter,
        onSearch: (params) => {
            const filters: CrudFilters = generateCrudFilters(params, "render");
            setSorter(generateSortOrder(params, initialSortOrder));
            setPageSize(generatePageSize(params));
            return filters;
        },
        queryOptions: {
            enabled: (savedFilters !== undefined)
        }

    });

    const { data: metadata } = useOne<IResourcingRequestMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.RESOURCINGREQUEST,
        queryOptions: {
            enabled: true,
            staleTime: 300000
        }
    });

    const totalRequests = tableQueryResult?.data?.total ?? 0;
    const helpFilePath = metadata?.data?.fields?.find(x => x.name === "help")?.attachment || "";
    const metaConfig = metadata?.data?.config;
    const [filterOpen, switchFilterForm] = useState(false);
    const [helpOpen, switchHelpContent] = useState(false);
    const canCreate = (metadata?.data.config.roleIdsCanCreate ?? []).some(r => userObj?.roleIds.some(ri => r === ri));
    const columnPreference = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.RESOURCINGREQUEST)?.columns;
    const entityStates = metadata?.data?.states as IState[] ?? [];
    const [columnSelectionDrawer, switchColumnSelection] = useState(false);
    const [tableColumns, setTableColumns] = useState<IEntityListColumns>();
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    useEffect(() => {
        if (metaConfig) {
            setTableColumns(mergePreferences(metaConfig.listColumns, columnPreference, RESOURCE_PATH.RESOURCINGREQUEST));
        }
    }, [metaConfig, columnPreference]);

    const getTableColumns = (): ColumnsType<IResourcingRequest> => {
        const columns: ColumnsType<IResourcingRequest> = GetListTableColumns(tableColumns, sorter, RESOURCE_PATH.RESOURCINGREQUEST);
        return columns
    }
    const OnColumnSelection = () => {
        switchColumnSelection(false);
    }

    const clearFilters = () => {
        //Switch to System Default 
        saveToProfile(preferencesData(userObj.preferences, RESOURCE_PATH.RESOURCINGREQUEST, undefined, [], [], 10), true);
    }

    const { mutate } = useUpdate<IBaseProps>();
    const saveToProfile = (userPreferences: IUserPreferences, clear: boolean = false) => {
        // Save the preference to profile
        mutate({
            dataProviderName: DATAPROVIDER_UPDATE,
            resource: RESOURCE_PATH.PROFILE,
            id: userObj.id,
            values: { preferences: userPreferences },
            successNotification: false,
            errorNotification: false
        },
            {
                onSuccess: () => {
                    //If clear, reset the form to facttory default
                    if (clear) {
                        searchFormProps.form?.setFieldsValue({});
                        searchFormProps.form?.submit();
                    }

                    // Remove the user Profile local data to ensure latest preferences are loaded
                    removeLocalSessionStorage(MY_PROFILE);
                },
                onError: () => {
                }
            }
        );
    }


    return (
        <List
            headerProps={{
                title: "Resourcing Requests",
                tags: <>
                    <Tag color={"default"}>
                        {tableQueryResult.isLoading ? "Loading" : totalRequests} Request{totalRequests !== 1 && "s"}
                    </Tag>
                    <ShowFilterIndicator filters={savedFilters} />
                </>
            }}
            headerButtons={() => (
                <>
                    {canCreate &&
                        <CreateButton icon={<Icons.PlusOutlined />}>Create Request</CreateButton>
                    }
                    {/* <RequestImport/> */}
                    {savedFilters && savedFilters.length > 0 && savedFilters.some(fltr => fltr.value !== undefined) &&
                        <Button
                            onClick={() => {
                                clearFilters();
                            }}
                            icon={<Icons.ClearOutlined />}
                        >
                            Clear Filters
                        </Button>
                    }
                    <Button key="filter" icon={<Icons.SearchOutlined />} onClick={() => switchFilterForm(true)}>{metadata?.data?.config?.search?.textMessages?.buttonText || "Search & Filter"}</Button>
                    {helpFilePath &&
                        <Button key="help" icon={<Icons.ReadOutlined />} onClick={() => switchHelpContent(true)}>Help</Button>
                    }
                    {entityStates.length > 0 ? RenderWorkflowModal(entityStates) : <></>}
                    <Button key="colmnopt" icon={<Icons.ToolOutlined />} onClick={() => switchColumnSelection(true)}>Column Options</Button>
                </>
            )}
        >
            <Table {...tableProps}
                rowKey="id"
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
                }}
                columns={getTableColumns()}
                expandable={{
                    expandRowByClick: true,
                    expandedRowKeys: expandedRowKeys,
                    onExpand: (expanded, rec) => {
                        if (expanded) {
                            setExpandedRowKeys([rec.id]);
                        }
                        else {
                            setExpandedRowKeys([]);
                        }
                    },
                    expandedRowRender: record => <RequestExpand {...record} />
                }}
            />
            <Drawer
                title={metadata?.data?.config?.search?.textMessages?.drawerFormHeader || "Filter Requests"}
                placement="right"
                size="large"
                open={filterOpen}
                maskClosable={false}
                onClose={() => { switchFilterForm(false) }}
                forceRender={true}
            >
                {metaConfig &&
                    <RequestFilter
                        formProps={searchFormProps}
                        user={userObj}
                        onApplyFilter={() => switchFilterForm(false)}
                        defaultFilters={mapFiltersToFormFields(savedFilters, initialSortOrder, savedPageSize)}
                        listColumns={metaConfig.listColumns}
                    />
                }
            </Drawer>
            {(helpFilePath) &&
                <Drawer
                    title="FAQs"
                    placement="right"
                    size="large"
                    open={helpOpen}
                    onClose={() => { switchHelpContent(false) }}
                >
                    <RenderMDFile mdFilePath={helpFilePath} resourceName={RESOURCE_PATH.RESOURCINGREQUEST} />
                </Drawer>
            }
            <Drawer
                title="Column options"
                placement="right"
                size="default"
                open={columnSelectionDrawer}
                onClose={() => { switchColumnSelection(false) }}
            >
                <ListColumnPicker user={userObj} entityListColumns={tableColumns} onColumnSelection={OnColumnSelection} />
            </Drawer>
        </List>
    );
};
