import { Button, Col, Form, FormProps, Icons, Input, Row, Select } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { IArticleMetadata } from "interfaces/article";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";

export const ArticleFilter: React.FC<{ formProps: FormProps }> = (props) => {
    const { isLoading, data: metadata } = useOne<IArticleMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.ARTICLE,
        queryOptions: {
            enabled: true,
            staleTime: 300000
        }
    });

    const metaConfig = metadata?.data.config;
    return (
        isLoading ? <RenderSpinner message="Loading..." ></RenderSpinner> :
            <Form layout="vertical" {...props.formProps} autoComplete="off">
                <Row gutter={[10, 0]} align="bottom">
                    <Col xs={24} xl={24} md={12}>
                        <Form.Item
                            label={"Search"}
                            name="q"
                            tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Articles"}
                        >
                            <Input
                                placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Articles"}
                                prefix={<Icons.SearchOutlined />}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Category"
                            name="category"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Category"
                            >
                                {metaConfig?.category.sort().map((m, i) => (
                                    <Select.Option value={m} key={i}>{m}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Content Type"
                            name="contentType"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Content Type"
                            >
                                {metaConfig?.contentType.sort().map((m, i) => (
                                    <Select.Option value={m} key={i}>{m}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                type="primary"
                            >
                                Apply Filter(s)
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                onClick={() => { props.formProps.form?.resetFields() }}
                            >
                                Clear Filter(s)
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
    );
}