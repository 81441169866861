import { arrayExtensions } from "@architecture-innovation-transformation/lib-common";
import { AntdList, Avatar, Card, CreateButton, Divider, Icons, List, Space, Tag, Typography, useSimpleList } from "@pankod/refine-antd";
import { CrudFilters, IResourceComponentsProps, useNavigation } from "@pankod/refine-core";
import { IArticle } from "interfaces/article";
import { DATAPROVIDER_READ, HELIUM_LOGO_PATH, RESOURCE_PATH } from "scripts/site";

export const HeliumAIList: React.FC<IResourceComponentsProps> = () => {

  const { show } = useNavigation();

  const listFilter: CrudFilters = [
    {
      field: "category",
      operator: "eq",
      value: "AI"
    }
  ];

  const { Paragraph } = Typography;
  const { listProps } = useSimpleList<IArticle>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.ARTICLE,
    permanentFilter: listFilter,
    pagination: { pageSize: 12 },
  });

  return (
    <List
      headerProps={{
        title: "Helium AI Catalog"
      }}
      headerButtons={() => (
        <>
          <CreateButton resourceNameOrRouteName="article" icon={<Icons.PlusOutlined />}>Add</CreateButton>
        </>
      )}
    >
      <AntdList
        grid={{
          gutter: 8,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 4,
        }}
        className="artcl-lst"
        {...listProps}
        renderItem={(item, indx) => (
          <Card
            bodyStyle={{ height: "250px" }}
            key={`evnt-crd-${indx}`}
            cover={
              <div className="artcl-img">
                <Avatar
                  size={200}
                  src={item.featuredImage ? item?.articleImages?.find(img => img.uid === item.featuredImage)?.url : HELIUM_LOGO_PATH}
                  alt={item.name}
                  onClick={() => show(RESOURCE_PATH.ARTICLE, item.id)}
                  style={{ cursor: "pointer" }}
                />
                <Divider style={{ margin: "1rem 0 0" }}></Divider>
              </div>
            }
          >
            <Card.Meta
              title={
                <Paragraph
                  ellipsis={{ rows: 2, tooltip: true }}
                  className="artcl-ttl"
                >
                  {item.name}
                </Paragraph>
              }
              description={
                <>
                  <Paragraph
                    ellipsis={{ rows: 3, tooltip: true }}
                    className="artcl-dscp"
                  >
                    {item.description}
                  </Paragraph>
                  <Paragraph className="artcl-tgs">
                    <Space wrap>
                      <Tag style={{ margin: "5px" }} color={"green"}>{item?.contentType}</Tag>
                      {arrayExtensions.validateArray(item?.tagList ?? []) ? item?.tagList?.map((e, i) => <Tag key={`tag-${i}`}>{e}</Tag>) : ""}
                    </Space>
                  </Paragraph>
                </>
              }
            />
          </Card>
        )
        }
      />
    </List >
  );
};