import { Button, Divider, Drawer, Icons, Space, Spin, Tag, message } from "@pankod/refine-antd";
import { useOne, useUpdate } from "@pankod/refine-core";
import { ISocial } from "interfaces/article";
import { useEffect, useState } from "react";
import { DATAPROVIDER_SOCIAL, RESOURCE_PATH } from "scripts/site";
import { SocialComments } from "./socialComments";

export declare type socialProps = {
    activeMode: boolean;
    socialElementId: string;
};

export const RenderSocialButtons: React.FC<socialProps> = ({
    socialElementId,
    activeMode
}) => {

    const { mutate } = useUpdate<ISocial>();
    const [showCommentsForm, setShowCommentsForm] = useState<boolean>(false);
    const [likeCount, setLikeCount] = useState<number>(0);
    const [viewCount, setViewCount] = useState<number>(0);
    const [isLiked, setIsLiked] = useState<boolean>(false);
    const [isViewed, setIsViewed] = useState<boolean>(true);
    const [linkCopied, setLinkCopied] = useState<boolean>(false);

    const { isLoading, data: socialRecord } = useOne<ISocial>({
        dataProviderName: DATAPROVIDER_SOCIAL,
        resource: RESOURCE_PATH.SOCIAL,
        id: socialElementId,
        queryOptions: {
            enabled: socialElementId !== undefined
        }
    });

    const handleInteraction = (mode: string) => {
        // Update the social Element as per the mode
        mutate({
            dataProviderName: DATAPROVIDER_SOCIAL,
            resource: RESOURCE_PATH.SOCIAL,
            id: socialElementId,
            values: { operation: mode },
            successNotification: false,
            errorNotification: false
        },
            {
                onSuccess: () => {
                    if (mode === "like") {
                        setLikeCount(likeCount + 1);
                        setIsLiked(true);
                    } else if (mode === "view" && !isViewed) {
                        setIsViewed(true);
                        setViewCount(viewCount + 1);
                    }
                },
                onError: () => {
                }
            }
        );
    }

    const handleLinkCopy = () => {
        // Disable the button
        setLinkCopied(true);

        // Copy the link to clipboard
        navigator.clipboard.writeText(window.location.href);
        message.success("Link copied to clipboard");

        // Enable the button after 5 seconds
        setTimeout(() => {
            setLinkCopied(false);
        }, 5000);
    }

    const record = socialRecord?.data;
    useEffect(() => {
        if (activeMode) {
            setTimeout(() => {
                handleInteraction("view");
            }, 10000);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (record) {
            setLikeCount(record.likeCount);
            setViewCount(record.viewCount);
            setIsLiked(record.isLiked);
            setIsViewed(record.isViewed);
        }
    }, [record]);

    return (
        <Spin spinning={isLoading}>
            <Space wrap style={{ marginTop: "1rem" }}>
                {activeMode &&
                    <>
                        <Button
                            type="default"
                            disabled={isLiked}
                            icon={<Icons.LikeOutlined />}
                            title={isLiked ? "You liked this article" : "Like this article"}
                            size="small"
                            onClick={() => { handleInteraction("like"); }}>
                            Like
                        </Button>
                        <Button
                            type="default"
                            icon={<Icons.CommentOutlined />}
                            title="Post a comment"
                            size="small"
                            onClick={() => { setShowCommentsForm(true) }}>
                            Comments
                        </Button>
                        <Button
                            type="default"
                            icon={<Icons.LinkOutlined />}
                            disabled={linkCopied}
                            title="Copy link to share this article"
                            size="small"
                            onClick={() => handleLinkCopy()}>
                            Copy to share
                        </Button>
                        <Divider type="vertical" />
                    </>
                }
                <Tag icon={<Icons.EyeFilled />} title="Views" color="blue">
                    {viewCount}
                </Tag>
                <Tag icon={<Icons.LikeFilled />} title="Likes" color="blue">
                    {likeCount}
                </Tag>
            </Space>
            <Drawer
                title="Post a comment"
                placement="right"
                size="large"
                open={showCommentsForm}
                onClose={() => { setShowCommentsForm(false) }}
            >
                <SocialComments socialElementId={socialElementId} comments={record?.comments || []} />
            </Drawer>
        </Spin>
    );
};