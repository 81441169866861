import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Icons, List, Typography, useTable, CreateButton, Table, Button, TextField, getDefaultSortOrder, Input, Select, useSelect, Checkbox, Drawer, EditButton, Tag, FilterDropdown } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, LogicalFilter, useGetIdentity, useNavigation, useOne, useUpdateMany } from "@pankod/refine-core";
import { RenderMDFile } from "components/common/renderMDFile";
import { EventFilter } from "components/event/eventFilter";
import { ShowUserCard } from "components/profile/showUserCard";
import dayjs from "dayjs";
import { IBaseProps, IEvent, IEventFilterVariables, IEventMetadata, ILookup, IUser } from "interfaces";
import { ReactNode, useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_SUBSCRIBE, parseTimeZone, RESOURCE_PATH, roundToNearesetFive } from "scripts/site";

export const EventList: React.FC<IResourceComponentsProps> = () => {

  const { show } = useNavigation();
  const { data: userData } = useGetIdentity();
  const userObj = userData as IUser;
  const { mutate } = useUpdateMany<IBaseProps>();

  const { data: metadata } = useOne<IEventMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.EVENT,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });

  const eventFilter: CrudFilters = [
    // {
    //   operator: "or",
    //   value: [{
    //     field: "stateManager.state",
    //     operator: "ne",
    //     value: "scheduled"
    //   },
    //   {
    //     field: "stateManager.assignedTo ARRAY_CONTAINS",
    //     operator: "eq",
    //     value: userObj?.id
    //   }]
    // }
  ];

  const { tableProps, tableQueryResult, searchFormProps, sorter } = useTable<IEvent, HttpError, IEventFilterVariables>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.EVENT,
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc"
      }
    ],
    permanentFilter: eventFilter,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, eventCategory, eventAudience, eventMode, state } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      filters.push({
        field: "eventCategory",
        operator: "eq",
        value: eventCategory
      });
      filters.push({
        field: "eventAudience",
        operator: "eq",
        value: eventAudience
      });
      filters.push({
        field: "eventMode",
        operator: "eq",
        value: eventMode
      });
      filters.push({
        field: "stateManager.state",
        operator: "eq",
        value: state
      });
      return filters;
    }
  });

  const [filterOpen, switchFilterForm] = useState(false);
  const [helpOpen, switchHelpContent] = useState(false);

  const handleSubscription = (id: string, isSubscribed: boolean) => {
    let operation = isSubscribed ? "remove" : "add";

    mutate({
      dataProviderName: DATAPROVIDER_SUBSCRIBE,
      resource: RESOURCE_PATH.EVENT,
      ids: [id],
      values: { operation },
      successNotification: {
        key: 'subscription-updated',
        message: 'Subscription Updated',
        description: isSubscribed ? "Successfully unsubscribed from event updates." : "Successfully subscribed to event updates.",
        type: 'success'
      }
    },
      {
        onSuccess: () => {
          tableQueryResult.refetch();
        }
      }
    );
  }

  function renderActionIcons(item: IEvent, index: number): ReactNode[] {
    const actions = [];
    // Edit button
    if ((item.stateManager.state === "scheduled" || item.stateManager.state === "draft") &&
      ((item.eventOrganizer === userObj.id) || item.eventOrganizingTeam?.find(x => x === userObj.id))) {
      actions.push(
        <EditButton key={`edit-${index}`} size="small" recordItemId={item.id} />
      );
    }

    // Subscribe action - Available only when the event is scheduled
    if (item.stateManager.state === "scheduled") {
      let isSubscribed = item.eventParticipants?.find(x => x === userObj.id) ? true : false;
      actions.push(
        <Button
          size="small"
          key={`subscribe-${index}`}
          style={{ marginLeft: 3, color: `${isSubscribed ? "orange" : "green"}` }}
          onClick={(ev) => {
            ev.preventDefault();
            handleSubscription(item.id, isSubscribed);
          }}>
          <Icons.CalendarOutlined />
          {isSubscribed ? "Unsubscribe" : "Subscribe"}
        </Button>
      );
    }

    return actions;
  }

  const defaultOnChange = tableProps?.onChange ?? ((p, f, s, e) => { });
  const helpFilePath = metadata?.data?.fields?.find(x => x.name === "help")?.attachment || "";

  function rowClick(record: IEvent) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.EVENT, record.id);
      },
    };
  }
  const { selectProps: userProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "name",
    optionValue: "id",
    sort: [
      {
        field: "lastAccessedAt",
        order: "desc"
      },
    ],
  });

  const totalEvents = tableQueryResult?.data?.total ?? 0;
  return (
    <List

      headerProps={{
        title: "Helium Events",
        tags: <Tag color={"default"}>
          {tableQueryResult.isLoading ? "Loading" : totalEvents} Event{totalEvents !== 1 && "s"}
        </Tag>
      }}
      headerButtons={() => (
        <>
          <CreateButton icon={<Icons.PlusOutlined />}>Create Event</CreateButton>
          <Button key="filter" icon={<Icons.SearchOutlined />} onClick={() => switchFilterForm(true)}>{metadata?.data?.config?.search?.textMessages?.buttonText || "Search & Filter"}</Button>
          {helpFilePath &&
            <Button key="help" icon={<Icons.ReadOutlined />} onClick={() => switchHelpContent(true)}>Help</Button>
          }
        </>
      )}
    >
      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
        }}
        onChange={(p, f, s, e) => {
          eventFilter.forEach(fl => {
            // Set the value to blank string if filter value is undefined.
            // This will avoid filter getting removed from URL and getting reset to equal operator.
            let tempFl = fl as LogicalFilter;
            if (tempFl && !f[tempFl.field]) {
              f[tempFl.field] = [""];
            }
          });
          defaultOnChange(p, f, s, e);
        }}
      >
        <Table.Column
          dataIndex="name"
          key="name"
          title="Event Name"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"

          // Add text filter
          filterDropdown={(props) => (
            <FilterDropdown  {...props} >
              <Input />
            </FilterDropdown>
          )}
        />
        <Table.Column
          key="eventOrganizer"
          dataIndex="eventOrganizer"
          title="Organized by"
          onCell={rowClick}
          className="mouseHand"
          defaultSortOrder={getDefaultSortOrder("eventOrganizer", sorter)}
          sorter
          render={(value: string) =>
            <ShowUserCard id={value} />
          }

          // Add text filter
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Select User"
                {...userProps}
              />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="eventCategory"
          key="eventCategory"
          className="mouseHand"
          title="Category"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("eventCategory", sorter)}
          sorter
          onCell={rowClick}

          // Add text filter
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Checkbox.Group options={(metadata?.data.config.eventCategory ?? []).sort()} />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="eventAudience"
          key="eventAudience"
          className="mouseHand"
          title="Audience"
          width={100}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("eventAudience", sorter)}
          sorter
          onCell={rowClick}

          // Add text filter
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Checkbox.Group options={(metadata?.data.config.eventAudience ?? []).sort()} />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex={["stateManager", "state"]}
          key="stateManager.state"
          className="mouseHand"
          title="State"
          width={100}
          render={(value) => <TextField value={stringExtensions.capitalize(value)} />}
          defaultSortOrder={getDefaultSortOrder("stateManager.state", sorter)}
          sorter
          onCell={rowClick}

          // Add text filter
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Checkbox.Group options={(metadata?.data.states ?? []).map(a => a.state).sort()} />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="eventStartTime"
          key="eventStartTime"
          title="Event Starts at"
          width={200}
          render={(value) => <TextField style={{ fontSize: 10 }} value={`${dayjs(value).format('LLLL')} (${parseTimeZone(new Date().toString())})`} />}
          defaultSortOrder={getDefaultSortOrder("eventStartTime", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />
        <Table.Column
          dataIndex="duration"
          key="duration"
          title="Duration"
          width={100}
          render={(_, record) => (
            `${roundToNearesetFive(dayjs(record.eventEndTime).diff(dayjs(record.eventStartTime), "minute"))} minutes`
          )}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />
        <Table.Column
          dataIndex="associateAttending"
          key="associateAttending"
          title="Total Attendees"
          width={150}
          render={(_, record) => (
            `${record.eventParticipants?.length || 0} associate(s)`
          )}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />
        <Table.Column<IEvent>
          title="Actions"
          dataIndex="actions"
          render={(_, record, index) => (
            renderActionIcons(record, index)
          )}
        />
      </Table>
      <Drawer
        title={metadata?.data?.config?.search?.textMessages?.drawerFormHeader || "Filter Events"}
        placement="right"
        size="default"
        open={filterOpen}
        onClose={() => { switchFilterForm(false) }}
      >
        <EventFilter formProps={searchFormProps} />
      </Drawer>
      {(helpFilePath) &&
        <Drawer
          title="FAQs"
          placement="right"
          size="large"
          open={helpOpen}
          onClose={() => { switchHelpContent(false) }}
        >
          <RenderMDFile mdFilePath={helpFilePath} resourceName={RESOURCE_PATH.EVENT} />
        </Drawer>
      }
    </List>
  );
};