import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import {
  Button, Drawer, getDefaultSortOrder, Icons, List, Table, Tag, TextField, Typography, useTable
} from "@pankod/refine-antd";
import {
  CrudFilters, HttpError, IResourceComponentsProps, useNavigation,
  useOne
} from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { UserFilter } from "components/users/userFilter";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { IUser, IUserFilterVariables, IUserMetadata } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, parseTimeZone, RESOURCE_PATH } from "scripts/site";

export const PeopleList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();

  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IUser, HttpError, IUserFilterVariables>({
    dataProviderName: DATAPROVIDER_READ,
    initialSorter: [
      {
        field: "lastAccessedAt",
        order: "desc"
      },
    ],
    permanentFilter: [
      {
        field: "stateManager.state",
        operator: "eq",
        value: "active"
      },
      {
        field: "userType",
        operator: "eq",
        value: "employee"
      }
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, location, country, contextualMaster, hobbies, technicalExpertiseAreas, functionalExpertiseAreas } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      filters.push({
        field: "locationId",
        operator: "eq",
        value: location
      });
      filters.push({
        field: "countryId",
        operator: "eq",
        value: country
      });
      filters.push({
        field: "employee.contextualMaster",
        operator: "eq",
        value: contextualMaster
      });
      filters.push({
        field: "hobbies ARRAY_CONTAINS",
        operator: "eq",
        value: hobbies
      });
      filters.push({
        field: "technicalExpertiseAreas ARRAY_CONTAINS",
        operator: "eq",
        value: technicalExpertiseAreas
      });
      filters.push({
        field: "functionalExpertiseAreas ARRAY_CONTAINS",
        operator: "eq",
        value: functionalExpertiseAreas
      });
      return filters;
    }
  });

  function rowClick(record: IUser) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.PEOPLE, record.id);
      },
    };
  }

  const { data: entityMetadata } = useOne<IUserMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.PEOPLE,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });
  const metaConfig = entityMetadata?.data?.config;
  const [filterOpen, switchFilterForm] = useState(false);

  return (
    <List
      headerProps={{
        title: "User Profiles",
        tags: <Tag color={"default"}>
          {tableQueryResult.isLoading ? "Loading" : tableQueryResult?.data?.total} User Profile{tableQueryResult?.data?.total !== 1 && "s"}
        </Tag>,
        extra: <>
          <Button key="filter" icon={<Icons.SearchOutlined />} onClick={() => switchFilterForm(true)}>{metaConfig?.search?.textMessages?.buttonText || "Search & Filter"}</Button>
        </>
      }}
    >
      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
        }}
      >
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />
        <Table.Column
          dataIndex="id"
          key="id"
          onCell={rowClick}
          className="mouseHand"
          title="Email"
          render={(value) => <TextField value={value} copyable />}
          sorter
        />
        <Table.Column
          dataIndex="userType"
          title="User Type"
          onCell={rowClick}
          className="mouseHand"
          sorter
          render={(value) => <TextField value={stringExtensions.capitalize(value)} />}
        />
        <Table.Column
          dataIndex="managerUserId"
          title="TCS Manager"
          onCell={rowClick}
          className="mouseHand"
          sorter
          render={(value: string) => <ShowUserCard id={value} />}
        />
        <Table.Column
          dataIndex="countryId"
          title="Country"
          onCell={rowClick}
          className="mouseHand"
          defaultSortOrder={getDefaultSortOrder("countryId", sorter)}
          sorter
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="locationId"
          title="Location"
          onCell={rowClick}
          className="mouseHand"
          defaultSortOrder={getDefaultSortOrder("locationId", sorter)}
          sorter
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="lastAccessedAt"
          key="lastAccessedAt"
          title="Last Seen"
          render={(value: string, userObj: IUser) => <>
            {value && displayRelativeDate(value)}
            {
              userObj.userTime &&
              <><br />
                <Typography.Text style={{ color: "GrayText" }}>{parseTimeZone(userObj.userTime)}</Typography.Text>
              </>
            }
          </>}
          defaultSortOrder={getDefaultSortOrder("lastAccessedAt", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />
      </Table>
      <Drawer
        title={metaConfig?.search?.textMessages?.drawerFormHeader || "Users - Search & Filter"}
        placement="right"
        size="default"
        open={filterOpen}
        onClose={() => { switchFilterForm(false) }}
      >
        <UserFilter formProps={searchFormProps} mode="people" />
      </Drawer>
    </List>
  );
};
