import { arrayExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, DeleteButton, EditButton, Icons, message, Popconfirm, Show, Tabs } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCreate, useGetIdentity, useNavigation, useShow } from "@pankod/refine-core";
import { HistoryTab } from "components/common";
import { CandidateEvaluationList } from "components/resourcing/candidateEvaluationList";
import { PositionList } from "components/resourcing/positionList";
import { ShortlistedCandidateList } from "components/resourcing/shortlistedCandidateList";
import { RequestDetails } from "components/resourcing/showRequest";
import { SuggestedCandidateList } from "components/resourcing/suggestedCandidateList";
import { IUser } from "interfaces";
import { IDemand, IResourcingRequest } from "interfaces/resourcing";
import { useEffect, useState } from "react";
import { DATAPROVIDER_CREATE, DATAPROVIDER_DELETE, DATAPROVIDER_READ, getGUID, RESOURCE_PATH } from "scripts/site";

export const ResourcingRequestShow: React.FC<IResourceComponentsProps> = () => {

  const { queryResult } = useShow<IResourcingRequest>({
    dataProviderName: DATAPROVIDER_READ,
  });

  const { data: userData } = useGetIdentity();
  const { edit, list } = useNavigation();
  const userObj = userData as IUser;
  const { data, isLoading } = queryResult;
  const record = data?.data as IResourcingRequest;
  const { mutate: cloneRequest } = useCreate<IResourcingRequest>();

  const [canEdit, switchCanEdit] = useState(false);
  const [resourcingAdmin, switchResourcingAdmin] = useState(false);
  const [requestState, switchRequestState] = useState("draft");
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [cloningInProgress, setCloningInProgress] = useState(false);

  useEffect(() => {
    switchCanEdit(record?.stateManager.assignedTo?.some(x => x === userObj?.id));
    switchResourcingAdmin((["resourcingAdmin", "resourcingTeamMember"]).some(r => userObj?.entityRoleIds.some(ri => r === ri)));
    if (record?.stateManager) {
      switchRequestState(record?.stateManager.state);
    }
  }, [record?.stateManager, userObj?.id, userObj?.entityRoleIds])

  const handleCloning = () => {
    if (record) {
      setCloningInProgress(true);
      let excludeKeys = ["id", "stateManager", "requestID", "rgsIds", "positions", "requestReceivedDate", "updatedAt", "updatedBy", "createdAt", "createdBy", "_attachments", "_etag", "_partition", "_rid", "_self", "_ts", "replacementForEmployeeName", "replacementForEmployeeId", "replacementReason"];
      let baseRecord = record as any;
      let clonedRecord: any = { id: getGUID() };

      Object.keys(baseRecord).forEach(k => {
        if (!excludeKeys.includes(k)) {
          clonedRecord[k] = baseRecord[k];
        }
      });

      cloneRequest(
        {
          dataProviderName: DATAPROVIDER_CREATE,
          resource: RESOURCE_PATH.RESOURCINGREQUEST,
          values: clonedRecord,
        },
        {
          onError: () => {
            message.error(`Unable to copy resourcing request: ${record.id}`);
            setCloningInProgress(false);
          },
          onSuccess: () => {
            setCloningInProgress(false);
            setOpenConfirmBox(false);
            message.info("Redirecting to cloned request!");
            setTimeout(() => {
              edit(RESOURCE_PATH.RESOURCINGREQUEST, clonedRecord.id);
            }, 2000);
          }
        }
      )
    } else {
      // Unable to find the record details
      message.error(`Unable to fetch request details to be copied. Kindly try again.`);
      setOpenConfirmBox(false);
    }
  };

  let recordTabs: any[] = [];
  if (record) {
    // Add Main Tab
    recordTabs.push(
      {
        label: (<span><Icons.ReadFilled />Request Details</span>),
        key: "details",
        children: <>
          {record && <RequestDetails {...record as IDemand} />}
        </>
      }
    );

    // Add Positions Tab, if positions exists
    if (record.positions && arrayExtensions.validateArray(record.positions)) {
      recordTabs.push({
        label: (<span><Icons.ContactsFilled />Positions</span>),
        key: "reqpstns",
        children: <PositionList requestId={record?.id} requestor={record?.requestor} />
      });
    }

    // Add Candidates Tab, if request is in published state
    if (record.stateManager.state === "published") {
      recordTabs.push({
        label: (<span><Icons.UsergroupAddOutlined />Suggested Profiles</span>),
        key: "reqcndts",
        children: <SuggestedCandidateList request={record} />
      });

      // Add Shortlisted Candidate Tab
      recordTabs.push({
        label: (<span><Icons.LinkOutlined />Shortlisted Profiles</span>),
        key: "reqmpcndts",
        children: <ShortlistedCandidateList requestId={record.id} />
      });
    }

    // Evaluations Tab - Show from Publish state onwards
    if (!["draft", "submitted"].includes(record.stateManager.state)) {
      recordTabs.push({
        label: (<span><Icons.CheckCircleOutlined />Evaluated Profiles</span>),
        key: "reqevls",
        children: <CandidateEvaluationList requestId={record.id} />
      });
    }

    // Finally, add record history tab
    recordTabs.push({
      label: (<span><Icons.HistoryOutlined />History</span>),
      key: "stdorkflow",
      children: <HistoryTab stateManager={record.stateManager} tabPosition='left' />
    });
  }

  return (
    <Show
      isLoading={isLoading}
      headerProps={{
        title: "Resourcing Request",
        subTitle: `for ${record?.projectName}`,

      }}
      goBack=""
      headerButtons={
        <>
          {(canEdit && requestState === "draft") &&
            <>
              <DeleteButton dataProviderName={DATAPROVIDER_DELETE} onSuccess={() => {
                list(RESOURCE_PATH.RESOURCINGREQUEST);
              }}></DeleteButton>
              <EditButton></EditButton>
            </>
          }

          {(resourcingAdmin && requestState === "submitted") &&
            <EditButton></EditButton>
          }

          {(record && record.requestor === userObj.id && requestState !== "draft") &&
            <Popconfirm
              title="This operation will create a copy of this Request. Kindly Confirm to proceed with cloning, Cancel otherwise"
              open={openConfirmBox}
              onConfirm={handleCloning}
              okButtonProps={{ loading: cloningInProgress }}
              okText="Confirm"
              onCancel={() => { setOpenConfirmBox(false) }}
              placement="bottomLeft"
            >
              <Button
                onClick={() => { setOpenConfirmBox(true) }}
                icon={<Icons.CopyOutlined />}>Clone Request</Button>
            </Popconfirm>
          }
        </>
      }
    >
      <Tabs
        defaultActiveKey="details"
        items={recordTabs}
      />
    </Show >
  );
};
