import { arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { AccessControlProvider } from "@pankod/refine-core";
import { AAD_LOGIN, allowedResources, CACHE_DURATION, fetchAuthZ, fetchAuthZCheck, fetchClientPrincipal, getSessionStorageWithExpiry, setSessionStorageWithExpiry, webToApiAction } from "scripts/site";

export const AuthZProvider: AccessControlProvider = {
  can: async ({ resource, action, params }) => {

    let cacheKey = "/authz/";

    if (params && params.dataProviderName) {
      cacheKey += params.dataProviderName + "/";
    }

    cacheKey += resource.toLowerCase() + "/";
    if (params && params.dataProviderName && params.id) {
      cacheKey += params.id + "/";
    }
    cacheKey += action.toLowerCase();

    const cache = getSessionStorageWithExpiry(cacheKey);
    if (cache) {
      return cache;
    }

    const apiAction = webToApiAction(action);
    if (await fetchClientPrincipal()) {
      var isAuthZ = false;

      if (params && params.dataProviderName) {
        const resJson = await fetchAuthZCheck(resource, apiAction, params.dataProviderName, params?.id?.toString());
        if (resJson?.result === true) {
          isAuthZ = true;
        }
      }
      else {
        const authZJson = await fetchAuthZ();
        if (arrayExtensions.validateArray(authZJson)) {
          if (allowedResources.some(r => stringExtensions.stringEquals(r, resource))) {
            isAuthZ = true;
          }
          else {
            const matched = authZJson.find(a => stringExtensions.stringEquals(a.id, resource));
            if (matched) {
              const allowResult = matched.allowedActions.some(ac => ac === "*" || stringExtensions.stringEquals(apiAction, ac));
              const denyResult = matched.deniedActions.some(ac => ac === "*" || stringExtensions.stringEquals(apiAction, ac));
              isAuthZ = allowResult && !denyResult;
            }
          }
        }
      }

      const result = isAuthZ ? { can: true } : { can: false, reason: "Unauthorized" };
      setSessionStorageWithExpiry(cacheKey, result, CACHE_DURATION);
      return result;
    }
    else {
      window.location.href = AAD_LOGIN;
    }

    return { can: false, reason: "Unauthorized" };
  },
};