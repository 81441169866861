import { IUser } from "interfaces";
import { DATAPROVIDER_CREATE } from "scripts/site";

export function validateUser(importedData: any, mode: string): IUser {
    let user = {} as IUser;

    let id = importedData["id"];
    let managerUserId = importedData["managerUserId"];
    let userIdL2 = importedData["UserIdL2"];
    let userIdL1 = importedData["UserIdL1"];
    let adManager = importedData["adManager"];
    let defaultDomain = "@microsoft.com";
    // Get the fields values from the imported data
    if (id && id.length > 0) {
        // Formulate the user object, start with id
        // Append the domain name if not present
        if (!id.endsWith(".com")) {
            user.id = id.trim() + defaultDomain;
        } else {
            user.id = id.trim();
        }

        // Get the appropriate managerUserId
        if (managerUserId && checkAliasForVDash(managerUserId)) {
            user.managerUserId = managerUserId.trim();
        } else if (userIdL2 && checkAliasForVDash(userIdL2)) {
            user.managerUserId = userIdL2.trim();
        } else if (adManager && checkAliasForVDash(adManager)) {
            user.managerUserId = adManager;
        } else if (userIdL1) {
            user.managerUserId = userIdL1.trim();
        }
        // Append the domain name if not present
        if (user.managerUserId && !user.managerUserId.endsWith(".com")) {
            user.managerUserId = user.managerUserId + defaultDomain;
        }

        // Copy the other necessary properties
        if (mode === DATAPROVIDER_CREATE) {
            user.roleIds = importedData["roleIds"] || ["basicUser"];
            user.userType = importedData["userType"] || "employee";
            user.name = importedData["name"]?.trim();
        }

        user.countryId = importedData["countryId"]?.trim();
        user.locationId = importedData["locationId"]?.trim();
    }
    return user;
}

export function checkAliasForVDash(alias: string): boolean {
    if (alias && alias.length > 2 && alias.toLowerCase().startsWith("v-")) {
        return true;
    }
    return false;
}

export function checkIdForDeactivation(importedData: any): string {
    let id = importedData["id"];
    let status = importedData["userStatus"];
    if (id && id.length > 0 && status && status.toLowerCase() !== "active") {
        if (!id.endsWith(".com")) {
            id = id + "@microsoft.com";
        }
        return id;
    }
    return "";
}