import { Checkbox, Create, Form, Input, Radio, SaveButton, Select, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useOne } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { CancelButton } from "components/utils/cancelButton";
import { IAsset, IAssetMetadata, ILocations, ILookup, IUser } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, RESOURCE_PATH, buildAttachmentUrl } from "scripts/site";
import { EditAssetConfig } from "./editAssetConfig";

export const AssetCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, form } = useForm<IAsset>({
    dataProviderName: DATAPROVIDER_CREATE,
    redirect: "list"
  });


  const { data: metadata } = useOne<IAssetMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.ASSET,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });


  const metaConfig = metadata?.data.config;
  const metaFields = metadata?.data.fields;
  const serialNumberUrl = buildAttachmentUrl(RESOURCE_PATH.METADATA, RESOURCE_PATH.ASSET, metaFields?.find(f => f.name === "serialNumber")?.attachment);

  const { data: userData } = useGetIdentity();
  const userObj = userData as IUser;

  const canAllocate = (metaConfig?.roleIdsCanAssign ?? []).some(r => userObj?.entityRoleIds.some(ri => r === ri));
  const [assetType, setAssetType] = useState(undefined);
  const assetTypeObj = metaConfig?.assetTypes.find(a => a.name === assetType);


  const [assetId, setAssetId] = useState("");
  const [checkAssignment, setAssignment] = useState(false);
  const onAsignmentChange = (e: { target: { checked: boolean } }) => {
    setAssignment(e.target.checked);
    formProps.form?.setFieldsValue({ stateAction: e.target.checked ? "allocated" : "available" });
  };

  const { refetch: checkAssetId } = useOne<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.ASSET,
    id: assetId,
    queryOptions: {
      enabled: false,
    }
  });

  const { selectProps: userProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "nameEmail",
    optionValue: "id",
    sort: [
      {
        field: "lastAccessedAt",
        order: "desc"
      },
    ],
    queryOptions: {
      enabled: canAllocate,
    }
  });

  const { selectProps: countryProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.COUNTRY,
    optionLabel: "name",
    optionValue: "id",
    sort: [
      {
        field: "name",
        order: "asc"
      }
    ],
    fetchSize: 100
  });

  const { queryResult: locationProps } = useSelect<ILocations>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.LOCATION,
    optionLabel: "name",
    optionValue: "id",
    sort: [
      {
        field: "name",
        order: "asc"
      }
    ],
    fetchSize: 100
  });

  const [country, setCountry] = useState(formProps?.initialValues?.countryId)
  if (country === undefined && formProps.initialValues?.countryId !== undefined) {
    setCountry(formProps.initialValues.countryId)
  }
  let locations = locationProps.data?.data

  return (
    <Create
      title="Add an Asset"
      //saveButtonProps={saveButtonProps}
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} disabled={!assetType} />
          <CancelButton />
        </>
      )}

    // actionButtons={
    //   <>
    //     <SaveButton  {...saveButtonProps} disabled={!assetType} />
    //     <CancelButton />
    //   </>
    // }
    >
      <Form {...formProps}
        autoComplete="off"
        layout="vertical">
        {canAllocate
          ?
          <>
            <Form.Item
              name="assignAsset"
            >
              <Checkbox
                checked={checkAssignment}
                onChange={onAsignmentChange}>
                Do you want to allocate this asset?
              </Checkbox>
            </Form.Item>
            <Form.Item
              label="Allocate to"
              name="assignedTo"
              hidden={!checkAssignment}
              rules={[
                {
                  required: checkAssignment,
                  whitespace: true,
                  max: 50,
                  type: "email",
                  validator(rule, value) {
                    if (checkAssignment) {
                      formProps.form?.setFieldsValue({ stateAction: value && value === userObj?.id ? "accepted" : "allocated" });
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Select placeholder="Search Name or Email" {...userProps} allowClear />
            </Form.Item>
          </>
          : <Form.Item
            initialValue={userObj?.id}
            label="Allocate to"
            name="assignedTo"
          >
            <ShowUserCard id={userObj?.id} ></ShowUserCard>
          </Form.Item>
        }
        <Form.Item
          name="stateAction"
          hidden={true}
          initialValue={canAllocate ? "available" : "accepted"}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Asset Type"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true
            },
          ]}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={(metaConfig?.assetTypes ?? []).map(a => a.name)}
            onChange={(event) => {
              setAssetType(event.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Country"
          name="countryId"
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Select Country"
            showSearch
            {...countryProps}
            onChange={function (val) { setCountry(val); form.setFieldsValue({ 'location': null }) }}
          >
          </Select>
        </Form.Item>
        <Form.Item
          label="Location"
          name="location"
          rules={[
            {
              required: true,
              type: "string",
            }
          ]}
        >
          <Select
            allowClear
            placeholder={form.getFieldValue('countryId') ? "Select Location" : "Select a Country first"}
          >
            {locations?.filter(loc => loc.countryId === country).map((m, i) => (
              <Select.Option value={m.id} key={`lc-${i}`}>{m.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Asset ID"
          name="id"
          rules={[
            {
              required: true,
              type: "string",
              whitespace: true,
              max: 20,
              min: 5,
            },
            {
              validator: async (_, value: string) => {
                if (!value) {
                  return;
                }
                const { data } = await checkAssetId();
                if (data && data?.data?.id) {
                  return Promise.reject(
                    new Error("'Asset ID' already exists."),
                  );
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input placeholder="0XHWXXXX" onChange={(event) => {
            setAssetId(event.target.value);
          }} />
        </Form.Item>

        <Form.Item
          tooltip={<>
            Launch windows Powershell and execute below command to identify the Computer Serial Number:
            <br />
            <strong>Gwmi win32_Bios | Select -Expand SerialNumber</strong>
            {serialNumberUrl &&
              <div>
                <a href={serialNumberUrl} rel="noreferrer" target={"_blank"}>View detailed steps</a>
              </div>
            }
          </>}
          label="Serial Number"
          name="serialNumber"
          rules={[
            {
              required: true,
              type: "string",
              whitespace: true,
              min: 5,
              max: 20,
            }
          ]}
        >
          <Input />
        </Form.Item>

        {assetTypeObj ? <EditAssetConfig {...assetTypeObj} fields={metaFields} isCRRequired={true}></EditAssetConfig> : <></>}
      </Form>
    </Create>
  );
};
