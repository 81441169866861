import { Form, useForm, Edit, SaveButton, Spin } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";
import { ICandidate } from "interfaces/resourcing";
import { DATAPROVIDER_UPDATE } from "scripts/site";
import { RequestForm } from "./requestForm";

export const CandidateEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, queryResult, saveButtonProps } = useForm<ICandidate>({
    dataProviderName: DATAPROVIDER_UPDATE,
  });

  const record = queryResult?.data?.data;

  return (
    <Spin spinning={queryResult?.isLoading}>
      <Edit
        headerProps={{
          backIcon: false
        }}

        footerButtons={() => (
          <>
            <SaveButton {...saveButtonProps} />
            <CancelButton />
          </>
        )}>
        <Form
          {...formProps}
          layout="vertical"
          scrollToFirstError={true}

        >
          {record &&
            <RequestForm candidateType={record.candidateType} mode="edit" />
          }
        </Form>
      </Edit>
    </Spin>
  );
};

