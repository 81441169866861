import { Button, Drawer, Icons, Show, Table, Tabs, Tag } from "@pankod/refine-antd";
import { IResourceComponentsProps, useOne } from "@pankod/refine-core";
import { TagReview } from "components/manage/tagReview";
import { ITags, ITagsReview, ITagsReviewMetadata } from "interfaces";
import { useEffect, useState } from "react";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, SELECTION_LISTS } from "scripts/site";

export const TagsReviewList: React.FC<IResourceComponentsProps> = () => {

    const { data: metadata } = useOne<ITagsReviewMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.REVIEW_TAGS,
        queryOptions: {
            enabled: true,
            staleTime: 300000
        }
    });

    const { data: tagsData, isLoading, refetch } = useOne<ITagsReview>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.REVIEW_TAGS,
        id: SELECTION_LISTS.AI_SUGGESTIONS
    });

    const suggestionList = tagsData?.data.data;
    const tagLists = metadata?.data.config.lists.filter(lst => lst.type === "primary");
    const [reviewFormOpen, switchReviewForm] = useState(false);
    const [refetchData, switchRefetchFlag] = useState(false);
    const [reviewTag, setReviewTag] = useState<ITags>();
    const onFormSubmit = () => {
        switchReviewForm(false);
    };

    const refetchSuggestionsData = () => {
        switchRefetchFlag(true);
    }

    useEffect(() => {
        if (refetchData) {
            refetch();
            switchRefetchFlag(false);
        }
    }, [refetchData, refetch]);

    const getTabs = () => {
        let tabs: any[] = [];
        tagLists?.map((m, i) => (
            tabs.push(
                {
                    label: m.name,
                    key: i,
                    children: <Table
                        dataSource={suggestionList?.filter(item => item.tagType === m.id && item.reviewed !== true)}
                        rowKey="tagName"
                    >
                        <Table.Column
                            title="Tag"
                            dataIndex="tagName"
                            key={`tagname-${m.id}-${i}`}
                            render={(value) => (
                                <>
                                    {
                                        <Tag color="blue" style={{ margin: 6 }}>
                                            {value}
                                        </Tag>
                                    }
                                </>
                            )}
                        />
                        <Table.Column
                            title="Tag Type"
                            dataIndex="tagType"
                            key={`tagtype-${m.id}-${i}`}
                            render={(value) => (
                                tagLists.find(lst => lst.id === value)?.name
                            )}
                        />
                        <Table.Column
                            title="Suggested Tags"
                            dataIndex="suggestions"
                            key={`suggestions-${m.id}-${i}`}
                            render={(suggestions: string[]) => (
                                <>
                                    {suggestions.map(tag => (
                                        <Tag color="purple" style={{ margin: 6 }} key={tag.toLowerCase()}>
                                            {tag}
                                        </Tag>
                                    ))}
                                </>
                            )}
                        />
                        <Table.Column<ITags>
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record) => (
                                <Button
                                    type="primary"
                                    key="help"
                                    onClick={() => {
                                        setReviewTag(record);
                                        switchReviewForm(true);
                                    }}
                                    icon={<Icons.EyeOutlined />}>Review</Button>
                            )}
                        />
                    </Table>
                })
        ));
        return tabs;
    }

    return (
        <Show
            isLoading={isLoading}
            headerButtons={
                <></>
            }
            headerProps={{
                title: "Review user added tags"
            }}
        >
            <Tabs
                tabPosition="top"
                items={getTabs()}
            />
            <Drawer
                title="Review Tag"
                placement="right"
                size="large"
                open={reviewFormOpen}
                onClose={() => { switchReviewForm(false) }}
            >
                {reviewTag &&
                    <TagReview reviewTag={reviewTag} metadata={tagLists?.find(lst => lst.id === reviewTag.tagType)} onFormSubmission={onFormSubmit} refetch={refetchSuggestionsData} />
                }
            </Drawer>
        </Show>
    );
};
