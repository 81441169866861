import { ILookup, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, Col, Form, FormProps, Icons, Input, Row, Select, useSelect } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { IAssetMetadata } from "interfaces";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";
export declare type AssetFilterProps = {
    formProps: FormProps,
    hideSearch: boolean
};

export const AssetFilter: React.FC<AssetFilterProps> = (props) => {
    const { isLoading, data: metadata } = useOne<IAssetMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.ASSET,
        queryOptions: {
            enabled: true,
            staleTime: 300000
        }
    });
    const { selectProps: locationProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.LOCATION,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        fetchSize: 100
    });

    const metaConfig = metadata?.data.config;
    return (
        isLoading ? <RenderSpinner message="Loading..." ></RenderSpinner> :
            <Form layout="vertical" {...props.formProps} autoComplete="off">
                <Row gutter={[10, 0]} align="bottom">
                    <Col xs={24} xl={24} md={12}>
                        <Form.Item
                            label={"Search"}
                            name="q"
                            hidden={props.hideSearch}
                            tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Assets"}
                        >
                            <Input
                                placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Assets"}
                                prefix={<Icons.SearchOutlined />}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Asset State"
                            name="state"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Asset State"
                            >
                                {metadata?.data?.states.map((m, i) => (
                                    <Select.Option value={m.state} key={i}>{stringExtensions.capitalize(m.state)}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Asset Location"
                            name="location"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Asset Location"
                                {...locationProps}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Asset Type"
                            name="assetType"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Asset Type"
                            >
                                {metaConfig?.assetTypes?.sort().map((m, i) => (
                                    <Select.Option value={m.name} key={i}>{m.name}</Select.Option>
                                ))}

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                type="primary"
                            >
                                Apply Filter(s)
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                onClick={() => { props.formProps.form?.resetFields() }}
                            >
                                Clear Filter(s)
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
    );
}