import { IBaseProps } from "@architecture-innovation-transformation/lib-common";
import { Button, Divider, Drawer, Icons, RefreshButton, Show, Tabs, Tag } from "@pankod/refine-antd";
import { CanAccess, IResourceComponentsProps, useGetIdentity, useOne, useShow } from "@pankod/refine-core";
import { HistoryTab, RenderWorkflowModal } from "components/common";
import { CandidateEvaluationDetails } from "components/resourcing/candidateEvaluationDetails";
import { CandidateEvaluationForm } from "components/resourcing/candidateEvaluationForm";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { IState, IUser } from "interfaces";
import { ICandidateEvaluation, ICandidateEvaluationMetadata } from "interfaces/resourcing";
import { useEffect, useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, getStateDisplayName, RESOURCE_PATH } from "scripts/site";

export const CandidateEvaluationShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult, showId } = useShow<ICandidateEvaluation>({
        dataProviderName: DATAPROVIDER_READ
    });

    const { data: entityMetadata } = useOne<ICandidateEvaluationMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.CANDIDATEEVALUATION,
        queryOptions: {
            enabled: true,
            staleTime: 300000
        }
    });

    const { data: userData } = useGetIdentity();
    const user = userData as IUser;
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const entityStates: IState[] = entityMetadata?.data.states ?? [];
    const [evaluationFormOpen, switchEvaluationForm] = useState(false);
    const [refetchData, switchRefetchFlag] = useState(false);

    const onFormSubmit = () => {
        switchEvaluationForm(false);
    };
    const refetchEvaluation = () => {
        switchRefetchFlag(true);
    }

    useEffect(() => {
        if (refetchData) {
            queryResult.refetch();
            switchRefetchFlag(false);
        }
    }, [refetchData, queryResult]);

    return (
        <Show
            isLoading={isLoading}
            title={record?.name || "Loading..."}
            headerProps={{
                tags: <Tag color="blue">{record ? getStateDisplayName(record.stateManager.state, entityStates) : "Loading"}</Tag>,
                extra: <>
                    {
                        record && record.stateManager.assignedTo.includes(user.id) &&
                        <CanAccess key={`edit-${RESOURCE_PATH.CANDIDATEEVALUATION}`} resource={RESOURCE_PATH.CANDIDATEEVALUATION.toLowerCase()} action="edit" params={{ id: showId, dataProviderName: DATAPROVIDER_UPDATE }}>
                            <Button
                                key="fdbckfrm"
                                title="Add Feedback"
                                onClick={() => {
                                    switchEvaluationForm(true);
                                }}
                                icon={<Icons.ContainerOutlined />}>Add Feedback</Button>
                        </CanAccess>
                    }
                    {entityStates.length > 0 ? RenderWorkflowModal(entityStates) : <></>}
                    <RefreshButton dataProviderName={DATAPROVIDER_READ}></RefreshButton>
                </>
            }}
        >
            <Tabs
                items={[
                    {
                        label: (<span><Icons.IdcardOutlined />Info</span>),
                        key: "info",
                        children: <>{record && <CandidateEvaluationDetails {...record} />}</>
                    },
                    {
                        label: (<span><Icons.HistoryOutlined />History</span>),
                        key: "workflow",
                        children: <>{record &&
                            <HistoryTab stateManager={record.stateManager} tabPosition={'left'} />
                        }</>
                    }
                ]}
            />
            <Divider></Divider>
            <div style={{ marginTop: 15 }}>
                <ObjectMetadata {...record as IBaseProps} ></ObjectMetadata>
            </div>
            <Drawer
                title="Candidate Evaluation Feedback"
                placement="right"
                size="large"
                open={evaluationFormOpen}
                onClose={() => { switchEvaluationForm(false) }}
            >
                <CandidateEvaluationForm evaluationId={showId as string} onFormSubmission={onFormSubmit} refetch={refetchEvaluation} />
            </Drawer>
        </Show>
    );
};
