import { Divider, EditButton, RefreshButton, Show } from "@pankod/refine-antd";
import { IResourceComponentsProps, useShow, CanAccess } from "@pankod/refine-core";
import { LocationDetails } from "components/manage/locationDetails";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { IBaseProps, ILocations } from "interfaces";
import { DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH } from "scripts/site";

export const LocationShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<ILocations>({
        dataProviderName: DATAPROVIDER_READ
    });

    const { data, isLoading } = queryResult;
    const record = data?.data as ILocations;

    return (
        <Show
            isLoading={isLoading}
            headerProps={{
                title: record?.id
            }}
            headerButtons={
                () => (
                    <>
                        {record &&
                            <>
                                <CanAccess key={`${RESOURCE_PATH.LOCATION}-edit`} resource={RESOURCE_PATH.LOCATION.toLowerCase()} action="edit" params={{ dataProviderName: DATAPROVIDER_UPDATE, id: record.id }}>
                                    <EditButton></EditButton>
                                </CanAccess>
                            </>
                        }
                        <RefreshButton dataProviderName={DATAPROVIDER_READ}></RefreshButton>
                    </>
                )
            }
        >
            {record && <LocationDetails {...record} />}
            <Divider></Divider>
            <div style={{ marginTop: 15 }}>
                <ObjectMetadata {...record as IBaseProps} ></ObjectMetadata>
            </div>
        </Show >
    );
};
