import { Icons, Popover, Typography } from "@pankod/refine-antd";
import { CrudFilters } from "@pankod/refine-core";

export declare type FilterIndicatorProps = {
    filters?: CrudFilters
};

export const ShowFilterIndicator: React.FC<FilterIndicatorProps> = ({
    filters
}) => {
    return (
        <>
            {filters && filters.length > 0 && filters.some(fltr => fltr.value !== undefined) &&
                <Popover
                    content={
                        <>
                            <div style={{ fontSize: 14, marginBottom: 10 }}>
                                <Typography.Text>View/Change/Clear applied filters using the 'Search & Filter' button</Typography.Text>
                            </div>
                        </>
                    }
                    title="You are viewing a filtered list"
                    placement="rightBottom"
                    style={{ width: 600 }}
                >
                    <Icons.FilterTwoTone />
                </Popover >
            }
        </>
    );
};