import { arrayExtensions } from "@architecture-innovation-transformation/lib-common";
import { Collapse, Divider, Icons, Select, Show, Tabs, useSelect } from "@pankod/refine-antd";
import {
  IResourceComponentsProps, useList
} from "@pankod/refine-core";
import { IUser } from "interfaces";
import React, { useState } from "react";
import { JsonViewer } from '@textea/json-viewer'
import { DATAPROVIDER_SYSTEM, RESOURCE_PATH } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export const SystemList: React.FC<IResourceComponentsProps> = () => {

  const { data: entityData, isLoading } = useList({
    dataProviderName: DATAPROVIDER_SYSTEM,
    resource: RESOURCE_PATH.SYSTEM,
    config: {
      pagination: { pageSize: 50 }
    }
  });

  const { data: roleData, isLoading: roleLoading } = useList({
    dataProviderName: DATAPROVIDER_SYSTEM,
    resource: RESOURCE_PATH.ROLE,
    config: {
      pagination: { pageSize: 50 }
    }
  });

  const { selectProps: userProps, queryResult: userResult } = useSelect<IUser>({
    dataProviderName: DATAPROVIDER_SYSTEM,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "nameEmail",
    optionValue: "id",
    sort: [
      {
        field: "lastAccessedAt",
        order: "desc"
      },
    ],
  });

  const roleSorted = arrayExtensions.sortBy("level", (roleData?.data ?? [])).reverse();
  const entitySorted = arrayExtensions.sortBy("name", (entityData?.data ?? []));

  function compileActions(apiNames: string[], apis: any[]) {
    return arrayExtensions.arrayRemoveDuplicate(apis.filter(ap => apiNames.some(an => ap.name === an || an === "*")).flatMap((a: any) => a.actions));
  }

  function compileApis(construct: any[], apis: any[]) {
    const parsedScope = construct.map((c: string) => {
      const split = c.split("/");
      return {
        scope: split[0],
        api: split[1]
      };
    });

    const groupByScope = arrayExtensions.groupBy(parsedScope, "scope");

    const compiledResult = [];
    for (const property in groupByScope) {
      compiledResult.push({
        scope: property,
        actions: compileActions(arrayExtensions.arrayRemoveDuplicate((groupByScope[property] ?? []).flatMap((r: any) => r.api)), apis),
      });
    }
    return compiledResult;
  }

  function compileEntity(entity: any, entityRoleIds: any[]) {
    const filterRoles = entity.entityRoles.filter((er: any) => entityRoleIds.some((ri: string) => er.id === ri));

    const groupByRole = arrayExtensions.groupBy(filterRoles, "id");

    const compiledResult = [];
    for (const property in groupByRole) {
      compiledResult.push({
        id: property,
        allow: compileApis((groupByRole[property] ?? []).flatMap((r: any) => r.allow), entity.apis),
        deny: compileApis((groupByRole[property] ?? []).flatMap((r: any) => r.deny), entity.apis)
      });
    }
    return compiledResult;
  }

  function compileEntityRole(role: any) {
    const matchedEntities = entitySorted.filter(en => en.entityRoles.some((er: any) => role.entityRoleIds.some((ri: string) => er.id === ri)));

    return matchedEntities.map(m => ({
      entityName: m.name,
      entityRoles: compileEntity(m, role.entityRoleIds)
    }));
  }

  const rolePerms = roleSorted.map(r => ({
    id: r.id,
    name: r.name,
    access: compileEntityRole(r)
  }));

  const [userId, setUserId] = useState("");

  function distinctActions(actions: any[]) {
    return arrayExtensions.arrayRemoveDuplicate((actions ?? []).flatMap((r: any) => r.actions));
  }

  function groupEntityScope(entityArr: any[]) {
    const groupByScope = arrayExtensions.groupBy(entityArr, "scope");
    const compiledResult = [];
    for (const property in groupByScope) {
      compiledResult.push({
        scope: property,
        actions: distinctActions(groupByScope[property]),
      });
    }
    return compiledResult;
  }

  function groupEntityRole(entityArr: any[]) {
    const groupByEntityRoleId = arrayExtensions.groupBy(entityArr.flatMap((r: any) => r.entityRoles), "id");
    const compiledResult = [];
    for (const property in groupByEntityRoleId) {
      compiledResult.push({
        id: property,
        allow: groupEntityScope((groupByEntityRoleId[property] ?? []).flatMap((r: any) => r.allow)),
        deny: groupEntityScope((groupByEntityRoleId[property] ?? []).flatMap((r: any) => r.deny)),
      });
    }
    return compiledResult;
  }

  function compileUserPerms(rolePermsCompiled: any) {
    const userRoleIds = userResult.data?.data.find(u => u.id === userId)?.roleIds || [];
    const userRolePerms = userRoleIds.length > 0 ? rolePermsCompiled.filter((rp: any) => userRoleIds.some(r => r === rp.id)) : [];

    if (userRolePerms.length > 1) {
      const groupByEntityName = arrayExtensions.groupBy(userRolePerms.flatMap((r: any) => r.access), "entityName");
      const compiledAccess = [];
      for (const property in groupByEntityName) {
        compiledAccess.push({
          entityName: property,
          entityRoles: groupEntityRole(groupByEntityName[property])
        });
      }

      const result = [{
        id: "compiled",
        name: "Compiled",
        access: compiledAccess
      }]

      return result.concat(...userRolePerms);
    }
    return userRolePerms;
  }

  function renderRolePerms(rolePerms: any[]) {
    return (rolePerms.map((m, i) => ({
      label: m.name,
      key: "tab" + i,
      children:
        <Collapse key={"collapse" + i}>
          {(m.access ?? []).map((ac: any, ind: number) => (
            <Collapse.Panel header={ac.entityName} key={"panel" + ind}>
              <JsonViewer theme={getAppTheme()} value={ac.entityRoles} />
            </Collapse.Panel>
          ))}
        </Collapse>
    }
    )));
  }

  return (
    <div className="system">
      <Show
        headerProps={{
          title: "Metadata Viewer",
          extra: <></>
        }}
        isLoading={isLoading || roleLoading}>

        <Tabs
          centered
          items={[
            {
              label: (<span><Icons.FileTextOutlined />Entities Json</span>),
              key: "EntitiesJson",
              children: <Tabs
                tabPosition="left"
                items={entitySorted.map((m, i) => {
                  return (
                    {
                      label: m.name,
                      key: i,
                      children: <JsonViewer theme={getAppTheme()} value={m} />
                    } as any
                  )
                })}
              />
            },
            {
              label: (<span><Icons.FileTextOutlined />Roles Json</span>),
              key: "RolesJson",
              children: <Tabs
                tabPosition="left"
                items={roleSorted.map((m, i) => {
                  return (
                    {
                      label: m.name,
                      key: i,
                      children: <JsonViewer theme={getAppTheme()} value={m} />
                    } as any
                  )
                })}
              />
            },
            {
              label: (<span><Icons.FileProtectOutlined />Role Permissions</span>),
              key: "RolePerms",
              children: <Tabs
                tabPosition="left"
                items={renderRolePerms(rolePerms)}
              />
            },
            {
              label: (<span><Icons.SolutionOutlined />User Permissions</span>),
              key: "UserPerms",
              children: <>
                <Select placeholder="Search Name or Email" {...userProps} style={{ width: '95%', margin: "1rem" }} onSelect={(value: any, op: any) => {
                  setUserId(value);
                }} />
                <Divider></Divider>
                <Tabs
                  tabPosition="left"
                  items={renderRolePerms(compileUserPerms(rolePerms))}
                />
              </>
            }
          ]}
        />
      </Show>
    </div>
  );
};

