import { ButtonProps } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { ILookup } from "interfaces";
import { DATAPROVIDER_LOOKUP } from "scripts/site";
import { RenderLink } from "./renderLink";

export declare type LookupProps = ButtonProps & {
    id: string;
    resource: string;
    noClick?: boolean;
};

export const LookupButton: React.FC<LookupProps> = (props) => {

    const { data } = useOne<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: props.resource,
        id: props.id,
        queryOptions: {
            enabled: (props.id !== undefined && props.id !== "" && props.resource !== ""),
            staleTime: 300000
        },
    });

    return (
        <RenderLink
            name={data?.data.name}
            {...props}
        ></RenderLink>
    );
};