import { IBaseProps, arrayExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, DeleteButton, Divider, Drawer, EditButton, Icons, RefreshButton, Show, Tabs, Tag } from "@pankod/refine-antd";
import { CanAccess, IResourceComponentsProps, useNavigation, useOne, useShow, useUpdate } from "@pankod/refine-core";
import { HistoryTab } from "components/common";
import { StateButtons } from "components/common/stateButtons";
import { AssignCandidates } from "components/resourcing/assignCandidates";
import { CandidateDetails } from "components/resourcing/candidateDetails";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { IState, IStateTransitionComments } from "interfaces";
import { ICandidate, ICandidateBA, ICandidateExternal, ICandidateInternal, ICandidateMetadata } from "interfaces/resourcing";
import { useEffect, useState } from "react";
import { DATAPROVIDER_DELETE, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, getStateDisplayName, RESOURCE_PATH } from "scripts/site";

export const CandidateShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult, showId } = useShow<ICandidate | ICandidateInternal | ICandidateBA | ICandidateExternal>({
    dataProviderName: DATAPROVIDER_READ
  });

  const { data: entityMetadata } = useOne<ICandidateMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.CANDIDATE,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });

  const { data, isLoading } = queryResult;
  const { list, show } = useNavigation(); // used list to clear out the history
  const record = data?.data as ICandidate | ICandidateInternal | ICandidateBA | ICandidateExternal;
  const metaConfig = entityMetadata?.data?.config;
  const entityStates: IState[] = entityMetadata?.data.states ?? [];
  const { mutate: updateFeedback } = useUpdate<ICandidate>();
  const [disableView, setDisableView] = useState<boolean>(false);
  const [candidateAssignmentDrawer, switchCandidateAssignmentDrawer] = useState(false);
  const [refetchData, switchRefetchFlag] = useState(false);
  const refetchCandidate = () => {
    switchRefetchFlag(true);
  }

  useEffect(() => {
    if (refetchData) {
      queryResult.refetch();
      switchRefetchFlag(false);
    }
  }, [refetchData, queryResult]);

  const captureStateChange = (values: IStateTransitionComments, stateAction: string) => {
    setDisableView(true);
    let payload = {
      stateAction: stateAction,
      comments: values.comments,
      assignedTo: values.assignedTo,
      computeOnAction: values.computeOnAction,
      currentMappedRequestId: record.currentMappedRequestId
    };

    // Find the state transition details
    let currentState = entityStates.find(st => st.state === record.stateManager.state);
    if (currentState) {
      let nextState = currentState.nextStates.find(tr => tr.action === stateAction);
      if (nextState?.stateId === "available") {
        payload.currentMappedRequestId = "";
      }
    }

    updateFeedback(
      {
        resource: RESOURCE_PATH.CANDIDATE,
        dataProviderName: DATAPROVIDER_UPDATE,
        id: record.id,
        values: payload,
      },
      {
        onError: () => {
          setDisableView(false);
        },
        onSuccess: () => {
          queryResult.refetch();
          setDisableView(false);
        }
      }
    );

  }

  return (
    <Show
      isLoading={isLoading || disableView}
      title={record?.name || "Loading..."}
      headerProps={{
        tags: <Tag color="blue">{record ? getStateDisplayName(record.stateManager.state, entityStates) : "Loading"}</Tag>,
        extra: <>
          {
            record ?
              <CanAccess key={`stte-${RESOURCE_PATH.CANDIDATE}`} resource={RESOURCE_PATH.CANDIDATE.toLowerCase()} action="edit" params={{ id: showId, dataProviderName: DATAPROVIDER_UPDATE }}>
                <StateButtons {...record as IBaseProps}
                  resourceToAct={RESOURCE_PATH.CANDIDATE}
                  resourceToActId={record.id || "0"}
                  resourceToNavigate={RESOURCE_PATH.CANDIDATE}
                  hideActions={["assigned", "select", "release", "reject"]}
                  customMutate={captureStateChange}
                /> </CanAccess>
              : <></>
          }
          <CanAccess key={`edit-${RESOURCE_PATH.CANDIDATE}`} resource={RESOURCE_PATH.CANDIDATE.toLowerCase()} action="edit" params={{ id: showId, dataProviderName: DATAPROVIDER_UPDATE }}>
            {
              record?.stateManager.state === "available" ?
                <Button
                  key="nvgn-asgn-btn"
                  className="nvgn-btn"
                  icon={<Icons.LinkOutlined />}
                  title="Associate Candidate with Request"
                  onClick={() => { switchCandidateAssignmentDrawer(true) }}
                >
                  Assign Candidate
                </Button>
                : <></>
            }
            <EditButton></EditButton>
          </CanAccess>
          <RefreshButton dataProviderName={DATAPROVIDER_READ}></RefreshButton>
          {
            record?.stateManager.state === "draft" ?
              <CanAccess key={`del-${RESOURCE_PATH.CANDIDATE}`} resource={RESOURCE_PATH.CANDIDATE.toLowerCase()} action="delete" params={{ id: showId, dataProviderName: DATAPROVIDER_DELETE }}>
                <DeleteButton
                  dataProviderName={DATAPROVIDER_DELETE}
                  onSuccess={() => {
                    list(RESOURCE_PATH.CANDIDATE, "replace")
                  }}
                ></DeleteButton>
              </CanAccess> : <></>
          }
        </>
      }}
    >
      <Tabs
        tabBarExtraContent={
          {
            right: <>
              {
                record && record.currentEvaluationId &&
                <CanAccess key={`cdtevl-${RESOURCE_PATH.CANDIDATEEVALUATION}`} resource={RESOURCE_PATH.CANDIDATEEVALUATION.toLowerCase()} action="" params={{ id: record.currentEvaluationId, dataProviderName: DATAPROVIDER_UPDATE }}>
                  <Button
                    key="cndtevalvw"
                    icon={<Icons.EyeOutlined />}
                    title="View Current Evaluation"
                    onClick={() => {
                      show(RESOURCE_PATH.CANDIDATEEVALUATION, record.currentEvaluationId)
                    }}
                  >Current Evaluation</Button>
                </CanAccess>
              }
              {
                record && arrayExtensions.validateArray(record.resume) &&
                <Button key="cndtresume" style={{ marginLeft: 8 }} icon={<Icons.DownloadOutlined />} title="View Candidates Resume" onClick={() => window.open(record.resume[0].url, "_blank")}>Resume</Button>
              }
            </>
          }
        }
        items={[
          {
            label: (<span><Icons.IdcardOutlined />Info</span>),
            key: "info",
            children: <>{record && <CandidateDetails {...record} />}</>
          },
          {
            label: (<span><Icons.HistoryOutlined />History</span>),
            key: "workflow",
            children: <>{record &&
              <HistoryTab stateManager={record.stateManager} tabPosition={'left'} />
            }</>
          }
        ]}
      />
      <Divider></Divider>
      <div style={{ marginTop: 15 }}>
        <ObjectMetadata {...record as IBaseProps} ></ObjectMetadata>
      </div>
      <Drawer
        title="Assign Candidate"
        placement="right"
        size="large"
        open={candidateAssignmentDrawer}
        onClose={() => { switchCandidateAssignmentDrawer(false) }}
      >
        {record && metaConfig &&
          <AssignCandidates candidateIds={record.id} resourcingSPOCGroupName={metaConfig.deliveryTeamResourcingSPOCGroup} onFormSubmission={() => { switchCandidateAssignmentDrawer(false) }} refetch={refetchCandidate} />
        }
      </Drawer>
    </Show>
  );
};
