import { AuthProvider } from "@pankod/refine-core";
import { POST_LOGIN_REDIRECT } from "hashRouter/routeProvider";
import { AAD_LOGIN, fetchClientPrincipal, fetchUserIdentity, getRedirectionURL } from "scripts/site";

export const AuthNProvider: AuthProvider = {
    login: async () => {
        if (!await fetchClientPrincipal()) {
            let loginURL = AAD_LOGIN;

            // Check if we are been redirected for specific page
            let redirectURL = getRedirectionURL(window.location.hash);
            if (redirectURL) {
                loginURL += POST_LOGIN_REDIRECT + redirectURL;
            }

            window.location.href = loginURL
            Promise.reject();
        }
        return Promise.resolve();
    },
    logout: () => {
        return Promise.resolve();
    },
    checkError: (error) => {
        if (error.status === 401) { // || error.status === 403) {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: async () => {
        if (await fetchClientPrincipal()) {
            return Promise.resolve();
        }
        return Promise.reject();
    },
    getPermissions: () => Promise.reject(),
    getUserIdentity: async () => {
        const userJson = await fetchUserIdentity();
        if (userJson) {
            return Promise.resolve(userJson);
        }
        const clientPrincipal = await fetchClientPrincipal();
        if (clientPrincipal) {
            return Promise.resolve({
                id: clientPrincipal.userDetails,
                name: clientPrincipal.userDetails,
            });
        }
        return Promise.reject();
    },
};