import { Button, CreateButton, Drawer, Icons, List, Table, Tag, Typography, getDefaultSortOrder, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useNavigation, useOne } from "@pankod/refine-core";
import { ArticleFilter } from "components/article/articleFilter";
import { ShowUserCard } from "components/profile/showUserCard";
import dayjs from "dayjs";
import { IArticle, IArticleFilterVariables, IArticleMetadata } from "interfaces/article";
import { useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH } from "scripts/site";

export const ArticleList: React.FC<IResourceComponentsProps> = () => {

  const [filterOpen, switchFilterForm] = useState(false);
  const { data: entityMetadata } = useOne<IArticleMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.ARTICLE,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });
  const metaConfig = entityMetadata?.data?.config;
  const { show } = useNavigation();

  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IArticle, HttpError, IArticleFilterVariables>({
    dataProviderName: DATAPROVIDER_READ,
    initialSorter: [
      {
        field: "createdAt",
        order: "desc"
      },
    ],
    permanentFilter: [
      {
        field: "stateManager.state",
        operator: "eq",
        value: "active,archived"
      }
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, category, contentType, tag } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      filters.push({
        field: "category",
        operator: "eq",
        value: category
      });
      filters.push({
        field: "contentType",
        operator: "eq",
        value: contentType
      });
      filters.push({
        field: "tagList ARRAY_CONTAINS",
        operator: "eq",
        value: tag
      });
      return filters;
    }
  });

  function rowClick(record: IArticle) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.ARTICLE, record.id);
      },
    };
  }

  return (
    <List
      headerProps={{
        title: "Helium Stories",
        tags: <Tag color={"default"}>
          {tableQueryResult.isLoading ? "Loading" : tableQueryResult?.data?.total} Article{tableQueryResult?.data?.total !== 1 && "s"}
        </Tag>,
        extra: <>
          <CreateButton></CreateButton>
          <Button key="filter" icon={<Icons.SearchOutlined />} onClick={() => switchFilterForm(true)}>{metaConfig?.search?.textMessages?.buttonText || "Search & Filter"}</Button>
        </>
      }}
    >
      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
        }}
      >
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="category"
          title="Category"
          onCell={rowClick}
          className="mouseHand"
          sorter
        />

        <Table.Column
          dataIndex="contentType"
          title="Article Type"
          onCell={rowClick}
          className="mouseHand"
          sorter
        />

        <Table.Column
          dataIndex="authorName"
          title="Authors"
          onCell={rowClick}
          className="mouseHand"
          sorter
          render={(value: string[]) =>
            value && value.map((r, i) => (
              <div key={i} style={{ marginBottom: 5 }}>
                <ShowUserCard id={r} />
              </div>
            ))
          }
        />

        <Table.Column
          dataIndex="publishedDate"
          key="publishedDate"
          title="Published Date"
          render={(value: string) => <>
            {value &&
              <Typography.Text style={{ color: "GrayText" }}>{dayjs(value).format('LLLL')}</Typography.Text>
            }
          </>}
          defaultSortOrder={getDefaultSortOrder("publishedDate", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

      </Table>
      <Drawer
        title={metaConfig?.search?.textMessages?.drawerFormHeader || "Article - Search & Filter"}
        placement="right"
        size="default"
        open={filterOpen}
        onClose={() => { switchFilterForm(false) }}
      >
        <ArticleFilter formProps={searchFormProps} />
      </Drawer>
    </List>
  );
};