import { FormInstance, Icons, notification } from "@pankod/refine-antd";
import { useGetIdentity, useNavigation, useOne, useUpdate } from "@pankod/refine-core";
import { IBaseProps, IState, IStateTransitionComments, IUser, IWorkListMetadata } from "interfaces";
import React, { useEffect, useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_UPDATE, RESOURCE_PATH, STATE_ACTION } from "scripts/site";
import { ButtonDropdown, RenderCommentsModal, RenderTimelineModal } from '.';
import { CustomIcon } from "./CustomIcon";

export declare type StateButtonProps = IBaseProps & {
    resourceToAct: string,  // Entity on which state actions to be performed (Example: asset, evaluation, position, resourcing request)
    resourceToActId: string, // Entity ID 
    /* Optional and Conditional Parameters */
    resourceToNavigate?: string; // To which Entity Type we want to navigate (Example: asset, evaluation, position, resourcing request)
    navigateToId?: string; // If want to navigate to specific Entitiy of above entity type
    hideActions?: string[]; // Actions which we don't want to display in the state buttons
    validateBusinessRules?: () => boolean;  // Function to be called to validate any business rules before transition
    fetchAssignedToList?: boolean;  // Do we need to fetch assigned to List -> to show whom the entity will get assigned after state change
    skipAssignedToCheck?: boolean; // Do we want to skip assignedTO check? If skipped, it will not be checked if the entity is assigned to logged in user or not
    mode?: "standard" | "worklist"; // Display mode of state buttons
    form?: FormInstance;    // Form instance, if any form releated validation need tp be performed
    onSuccessFormValidate?: () => void; // Function to call after successfull validation
    onFailureFormValidate?: () => void; // Function to call after failed validation
    setFormPreValidationRules?: (stateAction: string) => void; // Function to set if any business rules need to be set before triggering validation
    customPostStateAction?: () => void; // If any actions to be performed after the state operation is successful, this will pass the responsibility of navigation to caller.
    customMutate?: (values: IStateTransitionComments, stateAction: string) => void; // If we want to execute custom mutate rather then simple state mutation
};
export const StateButtons: React.FC<StateButtonProps> = (
    {
        resourceToAct,
        resourceToActId,
        resourceToNavigate,
        navigateToId,
        hideActions = [],
        validateBusinessRules,
        fetchAssignedToList = false,
        skipAssignedToCheck = true,
        mode = "standard",
        form,
        onSuccessFormValidate,
        onFailureFormValidate,
        setFormPreValidationRules,
        customPostStateAction,
        customMutate,
        ...rest
    }) => {

    const { show, list, edit } = useNavigation();
    const { mutate } = useUpdate<IBaseProps>();
    const { data } = useGetIdentity();
    const user = data as IUser;

    const { data: entityMetadata, isLoading } = useOne<IWorkListMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: resourceToAct.toLowerCase(),
        queryOptions: {
            enabled: true,
            staleTime: 300000
        }
    });

    const [modalVisible, setModalVisible] = useState(false);
    const [actionName, setActionName] = useState<string>("");
    const [currentState, setCurrentState] = useState<IState>();
    const entityStates = entityMetadata?.data.states;
    let actions: any[] = [];

    const handleFormSubmission = (stateAction: string, nextState: string) => {
        var isValid = true;

        // Trigger the business rules validation, if caller is performing the validation
        if (validateBusinessRules) {
            isValid = validateBusinessRules();
            if (isValid) {
                setModalVisible(true);
                setActionName(stateAction);
                return;
            } else {
                notification.error({
                    message: 'Incomplete Details',
                    description:
                        'Please validate all the fields and save before submitting. Click save to preserve your responses.'
                });
                return;
            }
        }

        // Trigger the form Validation, if form to be validated by stateButtons component
        if (form) {
            if (setFormPreValidationRules) setFormPreValidationRules(stateAction);
            // Validate Form and Proceed
            form.validateFields().then((_values) => {
                if (onSuccessFormValidate) onSuccessFormValidate();
                setModalVisible(true);
                setActionName(stateAction);
                return;
            }).catch((_errorInfo) => {
                if (onFailureFormValidate) onFailureFormValidate();
                notification.error({
                    message: 'Incomplete Details',
                    description:
                        'Please validate all the fields and save before submitting. Click save to preserve your responses.'
                });
                return;
            });
            return;
        }

        // Default Case - Everything is good, just popup the comments window.
        if (isValid) {
            setModalVisible(true);
            setActionName(stateAction);
            return;
        }

    }

    // Modal operation functions
    const onModalSave = (values: IStateTransitionComments) => {
        setModalVisible(false);
        if (customMutate) {
            customMutate(values, actionName);
        } else {
            mutate({
                resource: `${resourceToAct}/${resourceToActId}/${STATE_ACTION}`,
                dataProviderName: DATAPROVIDER_UPDATE,
                id: "",
                values: {
                    stateAction: actionName,
                    comments: values.comments,
                    assignedTo: values.assignedTo,
                    computeOnAction: values.computeOnAction
                },
                successNotification: { key: "notificationKey", message: "Successful", description: `Successfully updated ${resourceToAct}`, type: "success" }
            },
                {
                    onSuccess: () => {
                        if (customPostStateAction) {
                            customPostStateAction(); // In this case Navigation will be responsibility of Component called state Buttons
                        } else if (resourceToNavigate) {
                            navigateToId && navigateToId.length > 0 ?
                                show(resourceToNavigate, navigateToId) :
                                list(resourceToNavigate, "push");
                        } else {
                            list(RESOURCE_PATH.PROFILE);
                        }
                    }
                }
            );
        }
    };

    const onModalCancel = () => {
        setModalVisible(false);
        if (onFailureFormValidate) onFailureFormValidate(); // Execute the Failure sequence in Main Window
    };

    const getActions = () => {
        // Add buttons only when all 3 conditions are met
        //  1. If current state is defined
        //  2. Next state exists
        //  3. The state is assigned to current signed-in user
        if (currentState && currentState.nextStates.length > 0 && (skipAssignedToCheck || rest.stateManager.assignedTo.includes(user.id))) {
            currentState.nextStates.forEach((st, indx) => {
                // In case specific state action need to be excluded from button groups
                // This was required for the evaluation form as reassign button is not required.

                let customIcon = st.buttonIcon ? CustomIcon(st.buttonIcon) : CustomIcon("CaretRightOutlined")

                if (hideActions && !hideActions.includes(st.action)) {
                    actions.push({
                        key: indx,
                        label: (
                            <div className="st-btn-dv" onClick={() => handleFormSubmission(st.action, st.stateId)}
                                style={{ color: `${st.buttonColor ?? null}` }}
                            >
                                {customIcon} {st.buttonText}
                            </div>
                        )
                    })
                }
            });
        }
        return actions;
    };

    useEffect(() => {
        // Populate Current state when metadata is loaded
        if (entityStates) {
            setCurrentState(entityStates.find(st => st.state === rest.stateManager.state));
        }
    }, [isLoading, entityStates, rest.stateManager.state]);

    if (mode === "worklist") {
        hideActions = entityMetadata?.data?.config?.worklistStatesToHide || [];
        actions.push({
            key: "review",
            label: (
                <div
                    className="st-btn-dv"
                    onClick={function (ev) {
                        edit(resourceToAct, resourceToActId);
                    }}
                > <Icons.IssuesCloseOutlined /> Review
                </div>
            )
        },
            {
                key: "history",
                label: (
                    <RenderTimelineModal stateManager={rest.stateManager} mode={mode} />
                )
            })
    }

    return (
        <>
            {!isLoading && currentState &&
                <>
                    <ButtonDropdown dropdownItems={getActions()} hideText={mode === "worklist"} />
                    <RenderCommentsModal
                        visible={modalVisible}
                        modalTitle="Please provide comments:"
                        onCancel={onModalCancel}
                        onSave={onModalSave}
                        stateAction={actionName}
                        entityType={resourceToAct}
                        entityId={resourceToActId}
                        currentState={currentState}
                        fetchAssignedToList={fetchAssignedToList}
                    />
                </>
            }
        </>
    );
};