import { Button, Drawer, ExportButton, Icons, List, Table, Tag, Typography, useTable } from "@pankod/refine-antd";
import { CanAccess, CrudFilters, CrudSorting, HttpError, IResourceComponentsProps, useExport, useGetIdentity, useNavigation, useOne, useUpdate } from "@pankod/refine-core";
import type { ColumnsType } from 'antd/es/table';
import { ButtonDropdown, RenderMDFile, RenderWorkflowModal, ShowFilterIndicator } from "components/common";
import { ManagePosition } from "components/resourcing/managePosition";
import { mapFiltersToFormFields } from "components/resourcing/mapFilters";
import { PositionFilter } from "components/resourcing/positionFilter";
import { generateCrudFilters, generatePageSize, generateSortOrder } from "components/utils/generateCrudFilters";
import { GetListTableColumns, mergePreferences, preferencesData } from "components/utils/getListTableColumns";
import { ListColumnPicker } from "components/utils/listColumnPicker";
import { IBaseProps, IEntityListColumns, IState, IUser, IUserPreferences } from "interfaces";
import { IDemand, IDemandFilterVariables, IDemandMetadata } from "interfaces/resourcing";
import { useEffect, useState } from "react";
import { DATAPROVIDER_EXPORT, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, MY_PROFILE, RESOURCE_PATH, removeLocalSessionStorage } from "scripts/site";

export const PositionList: React.FC<IResourceComponentsProps> = () => {
    const { data: userData } = useGetIdentity();
    const userObj = userData as IUser;
    const { show } = useNavigation();
    let savedFilters = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.POSITION)?.filters || [];
    let savedPageSize = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.POSITION)?.pageSize || 10;
    let savedSortOrder = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.POSITION)?.sortOrder;
    let initialSortOrder: CrudSorting = (savedSortOrder && savedSortOrder.length > 0) ? savedSortOrder : [{
        field: "updatedAt",
        order: "desc"
    }];

    const { tableProps, tableQueryResult, searchFormProps, sorter, setSorter, setPageSize } = useTable<IDemand, HttpError, IDemandFilterVariables>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.POSITION,
        initialFilter: savedFilters,
        initialSorter: initialSortOrder,
        initialPageSize: savedPageSize,
        onSearch: (params) => {
            const filters: CrudFilters = generateCrudFilters(params, "render");
            setSorter(generateSortOrder(params, initialSortOrder));
            setPageSize(generatePageSize(params));
            return filters;
        },
        queryOptions: {
            enabled: (savedFilters !== undefined)
        }
    });

    const { data: metadata } = useOne<IDemandMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.POSITION,
        queryOptions: {
            enabled: true,
            staleTime: 300000
        }
    });

    const totalRequests = tableQueryResult?.data?.total ?? 0;
    const helpFilePath = metadata?.data?.fields?.find(x => x.name === "help")?.attachment || "";
    const entityStates = metadata?.data?.states as IState[] ?? [];
    const metaConfig = metadata?.data?.config;
    const columnPreference = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.POSITION)?.columns;
    const [filterOpen, switchFilterForm] = useState(false);
    const [helpOpen, switchHelpContent] = useState(false);
    const [refetchData, switchRefetchFlag] = useState(false);
    const [currentPosition, setCurrentPosition] = useState<IDemand>();
    const [resourcingTeam, switchResourcingTeam] = useState(false);
    const [reviewFormOpen, switchReviewForm] = useState(false);
    const [columnSelectionDrawer, switchColumnSelection] = useState(false);
    const [tableColumns, setTableColumns] = useState<IEntityListColumns>();

    const { triggerExport, isLoading: exportLoading } = useExport<any>({
        dataProviderName: DATAPROVIDER_EXPORT,
        resourceName: RESOURCE_PATH.POSITION,
        pageSize: metaConfig?.export.pageSize ?? 100,
    });

    useEffect(() => {
        if (refetchData) {
            tableQueryResult.refetch();
            switchRefetchFlag(false);
        }
        switchResourcingTeam((["resourcingAdmin", "resourcingTeamMember"]).some(r => userObj?.entityRoleIds.some(ri => r === ri)));
    }, [refetchData, tableQueryResult, userObj?.entityRoleIds]);

    useEffect(() => {
        if (metaConfig) {
            setTableColumns(mergePreferences(metaConfig.listColumns, columnPreference, RESOURCE_PATH.POSITION));
        }
    }, [metaConfig, columnPreference]);

    const refetchPositionsData = () => {
        switchRefetchFlag(true);
    }

    const onFormSubmit = () => {
        switchReviewForm(false);
    };

    const OnColumnSelection = () => {
        switchColumnSelection(false);
    }

    const getTableColumns = (): ColumnsType<IDemand> => {
        const columns: ColumnsType<IDemand> = GetListTableColumns(tableColumns, sorter, RESOURCE_PATH.POSITION);
        if (resourcingTeam) {
            columns.push(
                {
                    title: "Actions",
                    dataIndex: "actions",
                    render: (_, record) => (
                        <ButtonDropdown dropdownItems={[
                            {
                                key: "mnge",
                                label: (
                                    <div
                                        className="st-btn-dv"
                                        onClick={function (ev) {
                                            if (metaConfig && metaConfig.editableStates.includes(record.stateManager.state)) {
                                                ev?.stopPropagation();
                                                setCurrentPosition(record);
                                                switchReviewForm(true);
                                            }
                                        }}
                                    ><Icons.AimOutlined /> Manage
                                    </div>
                                ),
                                disabled: !(metaConfig && metaConfig.editableStates.includes(record.stateManager.state))
                            },
                            {
                                key: "vwrgst",
                                label: (
                                    <div
                                        className="st-btn-dv"
                                        onClick={(ev) => {
                                            ev?.stopPropagation();
                                            show(RESOURCE_PATH.RESOURCINGREQUEST, record.resourcingRequestID)
                                        }}
                                    ><Icons.EyeOutlined /> View Request
                                    </div>
                                )
                            }
                        ]} hideText />
                    )
                }
            );
        }
        return columns
    }


    const clearFilters = () => {
        //Switch to System Default 
        saveToProfile(preferencesData(userObj.preferences, RESOURCE_PATH.POSITION, undefined, [], [], 10), true);
    }

    const { mutate } = useUpdate<IBaseProps>();
    const saveToProfile = (userPreferences: IUserPreferences, clear: boolean = false) => {
        // Save the preference to profile
        mutate({
            dataProviderName: DATAPROVIDER_UPDATE,
            resource: RESOURCE_PATH.PROFILE,
            id: userObj.id,
            values: { preferences: userPreferences },
            successNotification: false,
            errorNotification: false
        },
            {
                onSuccess: () => {
                    //If clear, reset the form to facttory default
                    if (clear) {
                        searchFormProps.form?.setFieldsValue({});
                        searchFormProps.form?.submit();
                    }

                    // Remove the user Profile local data to ensure latest preferences are loaded
                    removeLocalSessionStorage(MY_PROFILE);
                },
                onError: () => {
                }
            }
        );
    }


    return (
        <List
            headerProps={{
                title: "Resourcing Positions",
                tags: <>
                    <Tag style={{ marginLeft: 6 }} color={"default"}>
                        {tableQueryResult.isLoading ? "Loading" : totalRequests} Position{totalRequests !== 1 && "s"}
                    </Tag>
                    <ShowFilterIndicator filters={savedFilters} />
                </>
            }}
            headerButtons={() => (
                <>
                    {savedFilters && savedFilters.length > 0 && savedFilters.some(fltr => fltr.value !== undefined) &&
                        <Button
                            onClick={() => {
                                clearFilters();
                            }}
                            icon={<Icons.ClearOutlined />}
                        >
                            Clear Filters
                        </Button>
                    }
                    <Button key="filter" icon={<Icons.SearchOutlined />} onClick={() => switchFilterForm(true)}>{metadata?.data?.config?.search?.textMessages?.buttonText || "Search & Filter"}</Button>
                    {helpFilePath &&
                        <Button key="help" icon={<Icons.ReadOutlined />} onClick={() => switchHelpContent(true)}>Help</Button>
                    }
                    {/* <PositionImport/> */}
                    {entityStates.length > 0 ? RenderWorkflowModal(entityStates) : <></>}
                    {metaConfig?.export.enabled &&
                        <CanAccess key={RESOURCE_PATH.POSITION} resource={RESOURCE_PATH.POSITION} action="list" params={{ dataProviderName: DATAPROVIDER_EXPORT }}>
                            <ExportButton onClick={triggerExport} loading={exportLoading} />
                        </CanAccess>
                    }
                    <Button key="colmnopt" icon={<Icons.ToolOutlined />} onClick={() => switchColumnSelection(true)}>Column Options</Button>
                </>
            )}
        >
            <Table {...tableProps}
                rowKey="id"
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
                }}
                columns={getTableColumns()}
            />
            <Drawer
                title={metadata?.data?.config?.search?.textMessages?.drawerFormHeader || "Filter Requests"}
                placement="right"
                size="large"
                open={filterOpen}
                maskClosable={false}
                onClose={() => { switchFilterForm(false) }}
                forceRender={true}
            >
                {metaConfig &&
                    <PositionFilter
                        formProps={searchFormProps}
                        user={userObj}
                        onApplyFilter={() => switchFilterForm(false)}
                        defaultFilters={mapFiltersToFormFields(savedFilters, initialSortOrder, savedPageSize)}
                        listColumns={metaConfig.listColumns}
                    />
                }
            </Drawer>
            {(helpFilePath) &&
                <Drawer
                    title="FAQs"
                    placement="right"
                    size="large"
                    open={helpOpen}
                    onClose={() => { switchHelpContent(false) }}
                >
                    <RenderMDFile mdFilePath={helpFilePath} resourceName={RESOURCE_PATH.POSITION} />
                </Drawer>
            }
            <Drawer
                title="Manage Position"
                placement="right"
                size="large"
                open={reviewFormOpen}
                onClose={() => { switchReviewForm(false) }}
            >
                {currentPosition && metadata?.data &&
                    <ManagePosition position={currentPosition} positionMetadata={metadata.data} onFormSubmission={onFormSubmit} refetch={refetchPositionsData} />
                }
            </Drawer>
            <Drawer
                title="Column options"
                placement="right"
                size="default"
                open={columnSelectionDrawer}
                onClose={() => { switchColumnSelection(false) }}
            >
                <ListColumnPicker user={userObj} entityListColumns={tableColumns} onColumnSelection={OnColumnSelection} />
            </Drawer>
        </List>
    );
};
