import { Button, Drawer, EditButton, Icons, ListButton, Show, Tabs } from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useNavigation, useOne, useShow } from "@pankod/refine-core";
import { HistoryTab } from "components/common";
import { ManagePosition } from "components/resourcing/managePosition";
import { RequestDetails } from "components/resourcing/showRequest";
import { IUser } from "interfaces";
import { IDemand, IDemandMetadata } from "interfaces/resourcing";
import { useEffect, useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH } from "scripts/site";


export const PositionShow: React.FC<IResourceComponentsProps> = () => {

    const { queryResult } = useShow<IDemand>({
        dataProviderName: DATAPROVIDER_READ,
    });

    const { data: userData } = useGetIdentity();
    const { show } = useNavigation();
    const userObj = userData as IUser;
    const { data, isLoading } = queryResult;
    const record = data?.data as IDemand;

    const [canEdit, switchCanEdit] = useState(false);
    const [resourcingTeam, switchResourcingTeam] = useState(false);
    const [resourcingAdmin, switchResourcingAdmin] = useState(false);
    const [requestState, switchRequestState] = useState("open");
    const [reviewFormOpen, switchReviewForm] = useState(false);
    const [refetchData, switchRefetchFlag] = useState(false);

    useEffect(() => {
        switchCanEdit(record?.stateManager.assignedTo?.some(x => x === userObj?.id));
        switchResourcingTeam((["resourcingAdmin", "resourcingTeamMember"]).some(r => userObj?.entityRoleIds.some(ri => r === ri)));
        switchResourcingAdmin((["resourcingAdmin"]).some(r => userObj?.entityRoleIds.some(ri => r === ri)));
        if (record?.stateManager) {
            switchRequestState(record?.stateManager.state);
        }
    }, [record?.stateManager, userObj?.id, userObj?.entityRoleIds])

    useEffect(() => {
        if (refetchData) {
            queryResult.refetch();
            switchRefetchFlag(false);
        }
    }, [refetchData, queryResult]);

    const { data: metadata } = useOne<IDemandMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.POSITION,
        queryOptions: {
            enabled: true,
            staleTime: 300000
        }
    });
    const metaConfig = metadata?.data?.config;

    const refetchPositionsData = () => {
        switchRefetchFlag(true);
    }

    const onFormSubmit = () => {
        switchReviewForm(false);
    };

    return (
        <Show
            isLoading={isLoading}
            headerProps={{
                title: "Resourcing Position",
                subTitle: `for ${record?.name}`,
            }}
            goBack=""
            headerButtons={
                <>
                    <Button
                        onClick={() => { show(RESOURCE_PATH.RESOURCINGREQUEST, record?.resourcingRequestID) }}
                        icon={<Icons.EyeOutlined />}>View Request</Button>
                    <ListButton></ListButton>
                    {((canEdit || resourcingTeam) && metaConfig && metaConfig.editableStates.includes(requestState)) &&
                        <Button
                            key="mnge"
                            title="Manage Position"
                            onClick={() => {
                                switchReviewForm(true);
                            }}
                            icon={<Icons.AimOutlined />}>Manage</Button>

                    }
                    {(resourcingAdmin && metaConfig && metaConfig.editableStates.includes(requestState)) &&
                        <EditButton>Rescope</EditButton>
                    }
                </>
            }
        >
            <Tabs
                defaultActiveKey="details"
                items={[
                    {
                        label: (<span><Icons.ReadFilled />Position Details</span>),
                        key: "details",
                        children: <>
                            {record && <RequestDetails {...record as IDemand} />}
                        </>
                    },
                    {
                        label: (<span><Icons.HistoryOutlined />History</span>),
                        key: "stdorkflow",
                        children: <>
                            {record &&
                                <HistoryTab stateManager={record.stateManager} tabPosition='left' />
                            }
                        </>
                    }
                ]}
            />
            <Drawer
                title="Manage Position"
                placement="right"
                size="large"
                open={reviewFormOpen}
                onClose={() => { switchReviewForm(false) }}
            >
                {metadata?.data && record &&
                    <ManagePosition position={record} positionMetadata={metadata.data} onFormSubmission={onFormSubmit} refetch={refetchPositionsData} />
                }
            </Drawer>
        </Show >
    );
};
