import { Divider, Edit, Form, Input, Radio, SaveButton, Icons, useForm, Select, useSelect, ListButton, RefreshButton, useFileUploadState, getValueFromEvent, Upload, message, Button, Row, Col, Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useOne } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";
import { ILookup } from "interfaces";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_UPDATE, DATAPROVIDER_UPLOAD, RESOURCE_PATH } from "scripts/site";
import MDEditor from "@uiw/react-md-editor";
import { getAppTheme } from "scripts/theme";
import { IArticle, IArticleMetadata } from "interfaces/article";
import { ImageParagraphAlignment, MediaType } from "interfaces/enum";
import { useEffect, useState } from "react";
import { arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";

export const ArticleEdit: React.FC<IResourceComponentsProps> = () => {

  const { formProps, saveButtonProps, queryResult } = useForm<IArticle>({
    dataProviderName: DATAPROVIDER_UPDATE
  });

  const { data: metadata } = useOne<IArticleMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.ARTICLE
  });
  const apiUrl = useApiUrl(DATAPROVIDER_UPLOAD);
  const metaConfig = metadata?.data?.config;

  const { isLoading: isUploading, onChange: onUploadChange } = useFileUploadState();
  const [imageList, setImageList] = useState([{ label: "No Image Uploaded", value: "no-image" }]);
  const record = queryResult?.data?.data;

  const { selectProps: usersList } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "nameEmail",
    optionValue: "id",
    sort: [{
      field: "lastAccessedAt",
      order: "desc"
    }]
  });


  useEffect(() => {
    let articleImages = queryResult?.data?.data?.articleImages || [];
    if (arrayExtensions.validateArray(articleImages)) {
      setImageList(articleImages.map(ut => ({ label: stringExtensions.capitalize(ut.name), value: ut.uid })));
    }
  }, [queryResult?.isFetching, queryResult?.data?.data?.articleImages])

  return (
    <Edit
      isLoading={queryResult?.isFetching}
      title={`Update - ${queryResult?.isFetching ? "" : record?.name}`}
      headerButtons={() => (
        <>
          <ListButton></ListButton>
          <RefreshButton></RefreshButton>
        </>
      )}
      footerButtons={() => (
        queryResult?.isFetching ? "" :
          <>
            <SaveButton {...saveButtonProps} disabled={isUploading} />
            <CancelButton />
          </>
      )}>
      <Form {...formProps} layout="vertical" autoComplete="off">
        <Divider orientation="left">Synopsis</Divider>
        <Form.Item
          label="Title"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              min: 5,
              max: 100
            },
          ]}
        >
          <Input placeholder="Title for the article" />
        </Form.Item>
        <Form.Item
          label="Short description"
          name="description"
          rules={[
            {
              required: true,
              whitespace: true,
              min: 15,
              max: 200
            },
          ]}
        >
          <Input.TextArea rows={2} placeholder="Short abstract outlining purpose, intended audience and benefits" />
        </Form.Item>
        <Divider orientation="left">Metadata</Divider>
        <Form.Item
          label="Category"
          name="category"
          tooltip="Category of Article"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={(metaConfig?.category ?? []).sort().map(a => a)}
          />
        </Form.Item>
        <Form.Item
          label="Content Type"
          name="contentType"
          tooltip="Content of Article"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={(metaConfig?.contentType ?? []).sort().map(a => a)}
          />
        </Form.Item>
        <Form.Item
          label="Tags"
          name="tagList"
          tooltip="Add tags"
          rules={[
            {
              required: true,
              type: "array"
            }
          ]}
        >
          <Select mode="tags"
            placeholder="Type a keyword and press enter. You can add as many as you want."
            tokenSeparators={[',']}
          >
          </Select>
        </Form.Item>
        <Divider orientation="left">Main Content</Divider>
        <Form.Item
          label="Upload images"
          name="articleImages"
          valuePropName="fileList"
          getValueFromEvent={getValueFromEvent}
          tooltip="Upload images to be used in the article, minimum image dimension should be 128x128 for better visual results"
          rules={[
            {
              required: false,
              validator(_, value) {
                if (value) {
                  setImageList(value.map((ut: any) => ({ label: stringExtensions.capitalize(ut.name), value: ut.uid })))
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Upload
            name="file"
            action={`${apiUrl}/${RESOURCE_PATH.ARTICLE}/${MediaType.ArticleImage}`}
            listType="picture-card"
            onChange={onUploadChange}
            maxCount={10}
            beforeUpload={(file) => {
              const isImageFile = file.type.search('image') === 0;
              if (!isImageFile) {
                message.error(`${file.name} is not an image file`);
              }
              return isImageFile;
            }}
          >
            <div>
              <Icons.PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Featured Image"
          name="featuredImage"
          tooltip="Select the image to be used for featuring this article"
          rules={[
            {
              required: false,
              whitespace: true
            }
          ]}
        >
          <Select placeholder="Select image from list of uploaded images" >
            {imageList.map((m, i) => (
              <Select.Option value={m.value} key={i}>{m.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.List
          name="body"
          rules={[
            {
              validator: async (_, body) => {
                if (!body || body.length < 1) {
                  return Promise.reject(new Error('Minimum 1 paragraph in article is required'));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  required={false}
                  key={field.key}
                  style={{ padding: 8, borderRadius: 8 }}
                  className="artcl-para"
                >
                  <Row gutter={[16, 16]} justify="end">
                    <Col span={12}>
                      <Typography.Title level={5}>Paragrah {field.key + 1}</Typography.Title>
                    </Col>
                    <Col offset={6} span={6}>
                      {
                        fields.length > 1 ? (
                          <Button
                            danger
                            type="dashed"
                            onClick={() => remove(field.name)}
                            icon={<Icons.MinusCircleOutlined />}
                          >
                            Remove Paragraph
                          </Button>
                        ) : null
                      }
                    </Col>
                  </Row>
                  <Form.Item
                    label="Content"
                    name={[index, "paragraph"]}
                    tooltip="Provide the content for Paragraph"
                    rules={[
                      {
                        required: true
                      },
                    ]}
                  >
                    <MDEditor height={250} data-color-mode={getAppTheme()} />
                  </Form.Item>
                  <Form.Item
                    label="Associate image with this paragraph"
                    name={[index, "isImageAdded"]}
                    tooltip="Associate an image with this paragraph"
                    initialValue={false}
                    rules={[
                      {
                        type: "boolean",
                        required: true
                      }
                    ]}
                  >
                    <Radio.Group
                      optionType="button"
                      buttonStyle="solid"
                      name="isImageAdded"
                    >
                      <Radio key="cd-01" value={true}>Yes</Radio>
                      <Radio key="cd-02" value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.body[index].isImageAdded !== currentValues.body[index].isImageAdded}
                  >
                    {({ getFieldValue }) =>
                      getFieldValue(["body", index, "isImageAdded"]) === true ? (
                        <>
                          <Form.Item
                            label="Image"
                            name={[index, "paraImage"]}
                            rules={[
                              {
                                required: false,
                                whitespace: true
                              }
                            ]}
                          >
                            <Select placeholder="Select image from list of uploaded images" >
                              {imageList.map((m, i) => (
                                <Select.Option value={m.value} key={i}>{m.label}</Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            label="Image alignment with respect to paragraph"
                            name={[index, "alignment"]}
                            initialValue="ImageLeftOfParagraph"
                            rules={[
                              {
                                required: false,
                                whitespace: true
                              }
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="alignment"
                              options={Object.entries(ImageParagraphAlignment)?.map(ut => ({
                                label: ut[1].toString(),
                                value: ut[0].toString()
                              }))}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Image Title"
                            name={[index, "imageTitle"]}
                            tooltip="Image title will be display below the image and can be used to reference image in article elsewhere."
                            rules={[
                              {
                                required: false,
                                whitespace: true
                              }
                            ]}
                          >
                            <Input placeholder="Provide title for image" />
                          </Form.Item>
                        </>
                      ) : null
                    }
                  </Form.Item>
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  style={{ width: "100%" }}
                  onClick={() => add()}
                  icon={<Icons.PlusOutlined />}
                >
                  Add Paragraph
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Divider orientation="left">Authors</Divider>
        <Form.Item
          label="Author(s)"
          name="authorName"
          rules={[
            {
              required: true,
              whitespace: true,
              type: "array",
              min: 1,
              max: 3,
              message: "Minimum 1 and maximum of 3 authors"
            },
          ]}
        >
          <Select placeholder="Search Name or Email" mode="multiple" {...usersList} allowClear />
        </Form.Item>
      </Form>
    </Edit>
  );
};