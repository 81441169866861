import { Button, Drawer, Icons, Show, Tabs } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCan, useOne } from "@pankod/refine-core";
import { OutreachForm, RenderMDFile, RenderPDFFile } from "components/common";
import { IInfoPagesMetadata } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";

export const SecurityInfoList: React.FC<IResourceComponentsProps> = () => {

  const { data: metadata, isLoading: metadataLoading } = useOne<IInfoPagesMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.INFO_SECURITY,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });
  const entityMetadata = metadata?.data;
  const [outreachOpen, switchOutreach] = useState(false);
  const canListExpertOutreach = useCan({
    resource: RESOURCE_PATH.EXPERT_OUTREACH,
    action: "create"
  });

  const getTabs = () => {
    let tabs: any[] = [];
    entityMetadata?.fields?.map((m, i) => (
      tabs.push(
        {
          label: m.name,
          key: i,
          children: <>
            {m.format === "pdf" ?
              <RenderPDFFile resourceName={RESOURCE_PATH.INFO_SECURITY} pdfFilePath={m.attachment} />
              :
              <RenderMDFile resourceName={RESOURCE_PATH.INFO_SECURITY} mdFilePath={m.attachment} />
            }
          </>
        })
    ));
    return tabs;
  }

  return (
    <Show
      isLoading={metadataLoading}
      headerProps={{
        title: entityMetadata?.config?.pageHeading,
        extra: canListExpertOutreach.data?.can && entityMetadata?.config?.connectButtonText &&
          <Button key="help" onClick={() => switchOutreach(true)} icon={<Icons.PhoneOutlined />}>{entityMetadata?.config?.connectButtonText}</Button>
      }}
    >
      <Tabs
        tabPosition="left"
        items={getTabs()}
      />

      {canListExpertOutreach.data?.can &&
        <Drawer
          title={entityMetadata?.config?.drawerTitle}
          placement="right"
          size={entityMetadata?.config?.drawerFormSize}
          open={outreachOpen}
          onClose={() => { switchOutreach(false) }}
        >
          <OutreachForm categoryType={entityMetadata?.config?.connectCategory} onFormSubmit={() => switchOutreach(false)} />
        </Drawer>
      }
    </Show>
  );
};
