import { arrayExtensions } from "@architecture-innovation-transformation/lib-common";
import { Divider, Icons, MarkdownField, Show, Space, Tabs, Tag, Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps, useOne, useShow } from "@pankod/refine-core";
import { MermaidView } from "components/common/mermaidView";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { IEvaluationModel, IGroup, IMetadata, IOption, IQuestion, IState } from "interfaces";
import { buildAttachmentUrl, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH, statesToMermaid, useAttachment } from "scripts/site";

const { Title, Text } = Typography;

export const EvaluationModelShow: React.FC<IResourceComponentsProps> = () => {

  const { queryResult } = useShow<IEvaluationModel>({
    dataProviderName: DATAPROVIDER_READ,
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const mdUrl = buildAttachmentUrl(RESOURCE_PATH.EVALUATIONMODEL, record?.id, record?.mdPath);

  const { response: mdData } = useAttachment(mdUrl);

  const { data: entityMetadata } = useOne<IMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.EVALUATION,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });
  const entityStates = entityMetadata?.data.states as IState[];

  const getTabs = () => {
    let tabs: any[] = [];

    // Add Details Tab
    tabs.push(
      {
        label: (<span><Icons.InfoOutlined />Details</span>),
        key: "details",
        children: <>
          <Title level={5}>Name</Title>
          <Text>{record?.name}</Text>

          <Title level={5}>Version</Title>
          <Text>{record?.version}</Text>

          <Title level={5}>Type</Title>
          <Text>{record?.modelType}</Text>

          <Title level={5}>Total Groups</Title>
          <Text>{record?.groups?.length}</Text>

          <Title level={5}>Created By</Title>
          <ShowUserCard id={record?.createdBy}></ShowUserCard>

          <Title style={{ marginTop: 15 }} level={5}>Last Updated</Title>
          {displayRelativeDate(new Date((record?._ts ?? 0) * 1000))}
        </>
      }
    );

    // Add MD file tab
    tabs.push({
      label: (<span><Icons.FileMarkdownOutlined />More</span>),
      key: "md",
      children: <MarkdownField value={mdData || "# Loading..."} ></MarkdownField>
    });

    // Add Workflow Tab
    tabs.push(
      {
        label: (<span><Icons.HistoryOutlined />Workflow</span>),
        key: "workflow",
        children: <MermaidView placeholderId="hstrymrmadmdl" graphDefinition={statesToMermaid(entityStates)}></MermaidView>
      }
    );

    // Add Model specific Tabs
    arrayExtensions.sortByOrder(record?.groups || []).forEach((gr, index) => {
      const g = gr as IGroup;
      tabs.push(
        {
          label: g.name,
          key: `${g.name}-${index}`,
          children: <>
            <Text strong style={{ marginRight: 5 }}>Group Name: {g.name}</Text>
            <Tag style={{ marginRight: 0 }} color="green">
              {g.weightage ?? 1}
            </Tag>
            <br />
            <Text>Total Questions: {g.questions.length}</Text>
            {
              arrayExtensions.sortByOrder(g.questions).map((q, qi) => {
                const quesData = q as IQuestion;
                return (
                  <span key={"grp - " + index + "ques -" + qi}>
                    <Divider dashed />
                    <Space wrap size={"small"} style={{ marginBottom: 5 }}>
                      <Text strong>{`${qi + 1} - ${quesData.name}`}</Text>
                      <Tag color="green">
                        {quesData.weightage ?? 1}
                      </Tag>
                    </Space>
                    <br />
                    <Tag color={quesData.multiSelect ? "cyan" : "purple"}>
                      {quesData.multiSelect ? "Multiple responses can be selected" : "Only 1 response can be selected"}
                    </Tag>
                    <br />
                    <Space wrap size="middle" style={{ marginTop: 20 }} direction={quesData.optionDisplay}>
                      {
                        arrayExtensions.sortByOrder(quesData.options).map((option, oi) => {
                          const optionData = option as IOption;
                          return (
                            <span key={"option" + oi}>
                              <Text style={{ marginRight: 5 }}>{optionData.name}</Text>
                              <Tag color="green">
                                {optionData.weightage ?? 1}
                              </Tag>
                            </span>
                          );
                        })
                      }
                    </Space>
                  </span>
                );
              })
            }
          </>
        }
      );
    })
    return tabs;
  }

  return (
    <>
      <Show isLoading={isLoading} >
        <Tabs
          defaultActiveKey="details"
          tabBarExtraContent={
            <Tag color="green">
              Indicates Weightage
            </Tag>
          }
          items={getTabs()}
        />
      </Show>
    </>
  );
};
