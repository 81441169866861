import type { ColumnsType } from 'antd/es/table';
import { Drawer, Spin, Table, Typography, useTable } from "@pankod/refine-antd";
import { useGetIdentity, useOne } from "@pankod/refine-core";
import { ICandidate, ICandidateMetadata } from "interfaces/resourcing";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH } from "scripts/site";
import { GetListTableColumns, mergePreferences } from 'components/utils/getListTableColumns';
import { useEffect, useState } from 'react';
import { IEntityListColumns, IUser } from 'interfaces';
import { ListColumnPicker } from 'components/utils/listColumnPicker';

export declare type ShortlistedCandidateProps = {
    requestId: string;
};

export const ShortlistedCandidateList: React.FC<ShortlistedCandidateProps> = ({ requestId }) => {
    const { data: userData } = useGetIdentity();
    const userObj = userData as IUser;
    const [tableColumns, setTableColumns] = useState<IEntityListColumns>();
    const [columnSelectionDrawer, switchColumnSelection] = useState(false);

    const { tableProps, sorter, tableQueryResult } = useTable<ICandidate>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.CANDIDATE,
        permanentFilter: [{
            field: "currentMappedRequestId",
            operator: "in",
            value: requestId
        }],
        queryOptions: {
            enabled: requestId.length > 0
        },
        hasPagination: false
    });

    const { data: entityMetadata } = useOne<ICandidateMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.CANDIDATE,
        queryOptions: {
            enabled: true,
            staleTime: 300000
        }
    });
    const metaConfig = entityMetadata?.data?.config;
    const columnPreference = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.CANDIDATE)?.columns;

    const getTableColumns = (): ColumnsType<ICandidate> => {
        const columns: ColumnsType<ICandidate> = GetListTableColumns(tableColumns, sorter, RESOURCE_PATH.CANDIDATE);
        return columns
    }

    const OnColumnSelection = () => {
        switchColumnSelection(false);
    }
    useEffect(() => {
        if (metaConfig) {
            setTableColumns(mergePreferences(metaConfig.listColumns, columnPreference, RESOURCE_PATH.CANDIDATE));
        }
    }, [metaConfig, columnPreference]);

    return (
        <Spin spinning={tableQueryResult.isLoading}>
            <Table {...tableProps}
                rowKey="id"
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
                }}
                columns={getTableColumns()}
            />
            <Drawer
                title="Column options"
                placement="right"
                size="default"
                open={columnSelectionDrawer}
                onClose={() => { switchColumnSelection(false) }}
            >
                <ListColumnPicker user={userObj} entityListColumns={tableColumns} onColumnSelection={OnColumnSelection} />
            </Drawer>
        </Spin>
    );
}