import type { ColumnsType } from 'antd/es/table';
import { Button, Drawer, Icons, Space, Spin, Table, Typography, message, useSimpleList, useTable } from "@pankod/refine-antd";
import { CanAccess, CrudFilters, useCreateMany, useGetIdentity, useOne } from "@pankod/refine-core";
import { ICandidate, ICandidateMetadata, IResourcingRequest, ICandidateBA, ICandidateExternal, ICandidateInternal, ICandidateEvaluation } from "interfaces/resourcing";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH, getGUID, getSkills } from "scripts/site";
import { GetListTableColumns, mergePreferences } from 'components/utils/getListTableColumns';
import { useEffect, useState } from 'react';
import { IEntityListColumns, IUser } from 'interfaces';
import { ListColumnPicker } from 'components/utils/listColumnPicker';
import { CandidateDetails } from './candidateDetails';

export declare type SuggestedCandidateProps = {
    request: IResourcingRequest
};

export const SuggestedCandidateList: React.FC<SuggestedCandidateProps> = ({ request }) => {
    const { data: userData } = useGetIdentity();
    const userObj = userData as IUser;
    const [tableColumns, setTableColumns] = useState<IEntityListColumns>();
    const [columnSelectionDrawer, switchColumnSelection] = useState(false);
    const [candidateDetailsOpen, switchCandidateDetailsOpen] = useState(false);
    const [disableView, setDisableView] = useState<boolean>(false);
    const [currentCandidate, setCurrentCandidate] = useState<ICandidate | ICandidateInternal | ICandidateBA | ICandidateExternal>();
    const { mutate: createEvaluations } = useCreateMany<ICandidateEvaluation>();

    // Grab the list of candidates already evaluated for the current request
    const { queryResult: candidateList } = useSimpleList<ICandidateEvaluation>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.CANDIDATEEVALUATION,
        permanentFilter: [{
            field: "requestId",
            operator: "eq",
            value: request.id
        }],
        hasPagination: false
    });

    const evaluatedCandidates = candidateList?.data?.data?.map(item => item?.candidateId) ?? [];

    const candidateFilter: CrudFilters = [{
        field: "countryId",
        operator: "eq",
        value: request.country
    },
    {
        field: "stateManager.state",
        operator: "eq",
        value: "available"
    },
    {
        field: "skills",
        operator: "contains",
        value: getSkills(request.requiredSkills, request.goodToHaveSkills, request.skillCategory)
    },
    {
        field: "_arrayfields",
        operator: "eq",
        value: "skills"
    }
    ];

    // Resourcing constraint filter to match with experience category in candidate entity
    if (request.resourceConstraint && request.resourceConstraint !== "None") {
        let constraintFilterValue = null;
        // For BA, we need to match with candidate type
        if (request.resourceConstraint === "BA Only") {
            candidateFilter.push({
                field: "candidateType",
                operator: "eq",
                value: "BA"
            });
        }
        // For FTE and Trainee, we need to match with experience category
        else if (request.resourceConstraint === "FTE Only") {
            constraintFilterValue = "Employee,Intern,Probationer";
        }
        else if (request.resourceConstraint === "Trainee Only") {
            constraintFilterValue = "Trainee,Intern";
        }
        if (constraintFilterValue) {
            candidateFilter.push({
                field: "experienceCategory",
                operator: "eq",
                value: constraintFilterValue
            });
        }

    }

    const { tableProps, sorter, tableQueryResult } = useTable<ICandidate>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.CANDIDATE,
        permanentFilter: candidateFilter,
        hasPagination: false,
        errorNotification: false,
        queryOptions: {
            enabled: candidateList.isFetched //Fire the query after list of already evaluated candidates
        }
    });
    const { data: entityMetadata } = useOne<ICandidateMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.CANDIDATE,
        queryOptions: {
            enabled: true,
            staleTime: 300000
        }
    });
    const metaConfig = entityMetadata?.data?.config;
    const columnPreference = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.CANDIDATE)?.columns;
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const hasSelected = selectedRowKeys.length > 0;

    const mapCandidate = () => {
        setDisableView(true);
        // Add respective mappings to the candidate and resourcing request
        let evaluations: any[] = [];
        selectedRowKeys.forEach((candidateId) => {
            let evalId = getGUID();
            // Create evaluation record for the candidate
            let evaluation = {
                id: evalId,
                name: `Evaluation of ${candidateId.toString()} for ${request.name}`,
                candidateId: candidateId.toString(),
                requestId: request.id,
                trCompleted: false
            }
            evaluations.push(evaluation);
        });

        createEvaluations(
            {
                resource: RESOURCE_PATH.CANDIDATEEVALUATION,
                dataProviderName: DATAPROVIDER_CREATE,
                values: evaluations,
                errorNotification: false,
                successNotification: false
            },
            {
                onError: () => {
                    message.error(`Unable to map ${selectedRowKeys.length} candidate(s) to current request. Evaluation creation failed.`);
                    setDisableView(false);
                },
                onSuccess: () => {
                    // Refetch the candidate list and set the view to normal
                    tableQueryResult.refetch();
                    setDisableView(false);
                    setSelectedRowKeys([]);
                }
            }
        )
    }

    const getTableColumns = (): ColumnsType<ICandidate> => {
        const columns: ColumnsType<ICandidate> = GetListTableColumns(tableColumns, sorter, RESOURCE_PATH.CANDIDATE);
        columns.push(
            {
                title: "Actions",
                dataIndex: "actions",
                render: (_, record) => (
                    <Button
                        type="primary"
                        icon={<Icons.EyeTwoTone />}
                        title={"Peek Candidate details"}
                        size="small"
                        onClick={function (ev) {
                            ev?.stopPropagation();
                            setCurrentCandidate(record);
                            switchCandidateDetailsOpen(true);
                        }}
                    >
                        Peek
                    </Button>
                )
            }
        );
        return columns
    }
    const filteredData = tableQueryResult?.data?.data?.filter(item => !evaluatedCandidates.includes(item.id)) ?? [];

    const OnColumnSelection = () => {
        switchColumnSelection(false);
    }
    useEffect(() => {
        if (metaConfig) {
            setTableColumns(mergePreferences(metaConfig.listColumns, columnPreference, RESOURCE_PATH.CANDIDATE));
        }
    }, [metaConfig, columnPreference]);

    return (
        <Spin spinning={disableView}>
            <Space style={{ marginBottom: 8 }}>
                <CanAccess key={request.id} resource={RESOURCE_PATH.RESOURCINGREQUEST} action="edit" params={{ id: request.id, dataProviderName: DATAPROVIDER_UPDATE }}>
                    <Button
                        type="primary"
                        key="nvgn-edit-btn"
                        disabled={!hasSelected}
                        className="nvgn-btn"
                        icon={<Icons.LinkOutlined />}
                        onClick={() => { mapCandidate() }}
                    >
                        Select for screening
                    </Button>
                </CanAccess>
                <Button key="colmnopt" icon={<Icons.ToolOutlined />} onClick={() => switchColumnSelection(true)}>Column Options</Button>
            </Space>

            <Table 
                loading={tableQueryResult?.isLoading}
                dataSource={filteredData}
                rowKey="id"
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
                }}
                rowSelection={{
                    selectedRowKeys,
                    onChange: (selectedRowKeys: React.Key[]) => {
                        setSelectedRowKeys(selectedRowKeys);
                    },
                    selections: [
                        Table.SELECTION_ALL,
                        Table.SELECTION_INVERT,
                        Table.SELECTION_NONE,
                    ],
                }}
                columns={getTableColumns()}
            />
            <Drawer
                title="Column options"
                placement="right"
                size="default"
                open={columnSelectionDrawer}
                onClose={() => { switchColumnSelection(false) }}
            >
                <ListColumnPicker user={userObj} entityListColumns={tableColumns} onColumnSelection={OnColumnSelection} />
            </Drawer>
            <Drawer
                title="Candidate Details"
                placement="right"
                size="large"
                open={candidateDetailsOpen}
                onClose={() => { switchCandidateDetailsOpen(false) }}
            >
                {currentCandidate &&
                    < CandidateDetails {...currentCandidate} />
                }
            </Drawer>
        </Spin>
    );
}