import { Create, DatePicker, Divider, Form, Icons, Input, Radio, Select, Tag, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useOne } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { IEvent, IEventMetadata, ILookup, IUser } from "interfaces";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, parseTimeZone, RESOURCE_PATH } from "scripts/site";
import dayjs from "dayjs";
import MDEditor from "@uiw/react-md-editor";
import { getAppTheme } from "scripts/theme";

export const EventCreate: React.FC<IResourceComponentsProps> = () => {

  const { formProps, saveButtonProps } = useForm<IEvent>({
    dataProviderName: DATAPROVIDER_CREATE,
    redirect: "list"
  });

  const { data: metadata } = useOne<IEventMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.EVENT,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });

  const { data: userData } = useGetIdentity();
  const userObj = userData as IUser;
  const metaConfig = metadata?.data.config;

  const { selectProps: usersList } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "nameEmail",
    optionValue: "id",
    filters: [{
      field: "id",
      operator: "ne",
      value: userObj.id
    }],
    sort: [{
      field: "lastAccessedAt",
      order: "desc"
    }]
  });

  return (
    <Create
      title="Configure an Event"
      saveButtonProps={{
        ...saveButtonProps
      }}
    >
      <Form {...formProps} layout="vertical" autoComplete="off">
        <Divider orientation="left">What</Divider>
        <Form.Item
          label="Event Title"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              min: 5,
              max: 100
            },
          ]}
        >
          <Input placeholder="Title for event" />
        </Form.Item>
        <Form.Item
          label="Event Description"
          name="description"
          rules={[
            {
              required: true,
              whitespace: true,
              min: 15,
              max: 200
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="Describe the event outlining purpose, intended audience and benefits" />
        </Form.Item>
        <Form.Item
          label="Target Audience"
          name="eventAudience"
          rules={[
            {
              required: true
            },
          ]}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={metaConfig?.eventAudience.sort()}
          />
        </Form.Item>
        <Form.Item
          label="Event Category"
          name="eventCategory"
          rules={[
            {
              required: true
            },
          ]}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={metaConfig?.eventCategory.sort()}
          />
        </Form.Item>
        <Divider orientation="left">When</Divider>
        <Form.Item
          label="Event Schedule"
          name="eventSchedule"
          tooltip="Select time as per local timezone."
          rules={[
            {
              required: true
            }
          ]}
        >
          <DatePicker.RangePicker
            format="YYYY-MM-DD hh:mm A"
            disabledDate={(current) => {
              // Can not select days before today and today
              return current && current.valueOf() < Date.now();
            }}
            showTime={true}
            minuteStep={30}
            defaultValue={[dayjs(), dayjs().add(30, 'm')]}
            suffixIcon={<Icons.DownOutlined />}
            renderExtraFooter={() => `Local Time Zone`}
            onChange={(value) => {
              if (value) {
                formProps.form?.setFieldsValue({
                  eventStartTime: value[0],
                  eventEndTime: value[1],
                  eventSchedule: [value[0], value[1]]
                })
              } else {
                formProps.form?.setFieldsValue({
                  eventStartTime: null,
                  eventEndTime: null,
                  eventSchedule: null
                })
              }
            }}
          />
          <Tag color="cyan" style={{ marginLeft: "15px" }}>
            {parseTimeZone(userObj?.userTime)}
          </Tag>
        </Form.Item>
        <Form.Item
          name="eventStartTime"
          hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item
          name="eventEndTime"
          hidden={true}>
          <Input />
        </Form.Item>
        <Divider orientation="left">Where</Divider>
        <Form.Item
          label="Event Delivery Mode"
          name="eventMode"
          rules={[
            {
              required: true
            },
          ]}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={metaConfig?.eventMode.sort()}
          />
        </Form.Item>
        <Form.Item
          label="Joining details of event"
          name="eventJoiningBlurb"
          tooltip="Blurb outlining on how to join the event, including meeting links, instructions, prerequisutes etc. "
          rules={[
            {
              required: true
            },
          ]}
        >
          <MDEditor data-color-mode={getAppTheme()} />
        </Form.Item>
        <Divider orientation="left">Event Organized By</Divider>
        <Form.Item
          label="Event Organizer"
          initialValue={userObj?.id}
          name="eventOrganizer"
          rules={[
            {
              required: true
            },
          ]}
        >
          <ShowUserCard id={userObj?.id} ></ShowUserCard>
        </Form.Item>
        <Form.Item
          label="Organizing Team"
          name="eventOrganizingTeam"
          rules={[
            {
              required: false,
              whitespace: true,
              type: "array"
            },
          ]}
        >
          <Select placeholder="Search Name or Email" mode="multiple" {...usersList} allowClear />
        </Form.Item>
      </Form>
    </Create>
  );
};