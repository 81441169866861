import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, Col, Form, FormProps, Icons, Input, Row, Select } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { IEventMetadata } from "interfaces";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";

export const EventFilter: React.FC<{ formProps: FormProps }> = (props) => {
    const { isLoading, data: metadata } = useOne<IEventMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.EVENT,
        queryOptions: {
            enabled: true,
            staleTime: 300000
        }
    });
    const metaConfig = metadata?.data.config;
    return (
        isLoading ? <RenderSpinner message="Loading..." ></RenderSpinner> :
            <Form layout="vertical" {...props.formProps} autoComplete="off">
                <Row gutter={[10, 0]} align="bottom">
                    <Col xs={24} xl={24} md={12}>
                        <Form.Item
                            label={"Search"}
                            name="q"
                            tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Events"}
                        >
                            <Input
                                placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Events"}
                                prefix={<Icons.SearchOutlined />}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Event Category"
                            name="eventCategory"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Category"
                            >
                                {metaConfig?.eventCategory.sort().map((m, i) => (
                                    <Select.Option value={m} key={i}>{m}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Target Audience"
                            name="eventAudience"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Target Audience"
                            >
                                {metaConfig?.eventAudience.sort().map((m, i) => (
                                    <Select.Option value={m} key={i}>{m}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Event Delivery Mode"
                            name="eventMode"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Delivery Mode"
                            >
                                {metaConfig?.eventMode.sort().map((m, i) => (
                                    <Select.Option value={m} key={i}>{m}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Event state"
                            name="state"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Event state"
                            >
                                {metadata?.data?.states.map((m, i) => (
                                    <Select.Option value={m.state} key={i}>{stringExtensions.capitalize(m.state)}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                type="primary"
                            >
                                Apply Filter(s)
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                onClick={() => { props.formProps.form?.resetFields() }}
                            >
                                Clear Filter(s)
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
    );
}