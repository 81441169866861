import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Avatar, Button, ButtonProps, Card, Divider, Popover, ShowButton, Typography } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { IUser } from "interfaces";
import { UserType } from "interfaces/enum";
import { DATAPROVIDER_LOOKUP, HELIUM_LOGO_PATH, RESOURCE_PATH } from "scripts/site";
import { ShowUserBasics } from "./showUserBasics";

export declare type UserCardProps = ButtonProps & {
    id: string | undefined;
};

export const ShowUserCard: React.FC<UserCardProps> = ({
    id,
    ...rest
}) => {
    const { data } = useOne<IUser>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        id: id ?? "",
        queryOptions: {
            enabled: (id !== undefined && id !== ""),
            staleTime: 30000
        },
    });
    const userObj = data?.data;
    const imageUrl = userObj?.userImages?.find(img => img.uid === userObj?.userProfileImage)?.url;

    return (
        userObj
            ? <Button
                type="link"
                size="small"
                style={{ padding: 0 }}
                {...rest}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Popover
                    content={
                        <Card size="small" >
                            <Card.Meta
                                avatar={<Avatar
                                    size="default"
                                    alt="Profile Image"
                                    src={imageUrl || HELIUM_LOGO_PATH}
                                />}
                                title={userObj.name}
                            />
                            <Divider style={{ marginTop: 15, marginBottom: 10, fontWeight: "normal", fontSize: 14, color: userObj.userType === UserType.Client ? "red" : "" }}>
                                {stringExtensions.capitalize(userObj.userType)}
                            </Divider>
                            <ShowUserBasics {...userObj} />
                            <ShowButton icon={<></>} type="link" resourceNameOrRouteName={RESOURCE_PATH.PEOPLE} size="small" recordItemId={id}>
                                View Full Profile
                            </ShowButton>
                        </Card>
                    }
                >
                    {userObj.name ?? id}
                </Popover >
            </Button >
            : <Typography.Text copyable={!!id}>{id}</Typography.Text>
    );
};


