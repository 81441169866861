import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Col, Create, Divider, Form, Input, InputNumber, Radio, Row, SaveButton, Select, Spin, TimePicker, useForm, useSelect } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";
import dayjs, { Dayjs } from "dayjs";
import { ICountry, ILocations, ILookup } from "interfaces";
import { useEffect, useState } from "react";
import { rowGutter, threeColumnLayout } from "scripts/layout";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, DATAPROVIDER_UPDATE, RESOURCE_PATH } from "scripts/site";

export declare type LocationFormProps = {
    entity: string;
    mode: "create" | "edit";
};

declare namespace Intl {
    type Key = 'calendar' | 'collation' | 'currency' | 'numberingSystem' | 'timeZone' | 'unit';

    function supportedValuesOf(input: Key): string[];
}

export const LocationForm: React.FC<LocationFormProps> = ({ entity = RESOURCE_PATH.COUNTRY, mode = "create" }) => {
    const { form, formProps, saveButtonProps, queryResult } = useForm<ICountry | ILocations>({
        dataProviderName: mode === "create" ? DATAPROVIDER_CREATE : DATAPROVIDER_UPDATE,
        redirect: entity === RESOURCE_PATH.COUNTRY ? "list" : "show",
    });

    const [entityId, setEntityId] = useState("");
    const [entityRequired, setEntityRequired] = useState(true);
    const [timezoneList, setTimezoneList] = useState<string[]>([]);

    const { refetch: checkEntityExists } = useOne<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: entity,
        id: entityId,
        queryOptions: {
            enabled: false,
        }
    });

    const { selectProps: userProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        sort: [
            {
                field: "lastAccessedAt",
                order: "desc"
            },
        ],
        fetchSize: 50
    });

    const { selectProps: resourcingAdminProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        sort: [
            {
                field: "lastAccessedAt",
                order: "desc"
            },
        ],
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "tcsmsstaffingadmin"
        }],
        fetchSize: 50
    });

    const { selectProps: assetAdminProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        sort: [
            {
                field: "lastAccessedAt",
                order: "desc"
            },
        ],
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "assetAdmin"
        }],
        fetchSize: 50
    });

    const { selectProps: spaceAdminProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "spaceContributor"
        }],
        sort: [
            {
                field: "lastAccessedAt",
                order: "desc"
            },
        ],
        fetchSize: 50
    });

    const { selectProps: groupProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.GROUPS,
        optionValue: "id",
        optionLabel: "name",
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        fetchSize: 50
    });

    const { selectProps: countryProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.COUNTRY,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            }
        ],
        fetchSize: 50,
        queryOptions: {
            enabled: entity === RESOURCE_PATH.LOCATION
        }
    });

    useEffect(() => {
        // Defaults for Location entity Form
        if (entity === RESOURCE_PATH.LOCATION) {
            setEntityRequired(false); // Set required fields to false for Location Form
            //Set timezone list
            setTimezoneList(Intl.supportedValuesOf('timeZone'));
        }
    }, [form, entity]);

    return (
        <Spin spinning={queryResult?.isLoading && mode === "edit"}>
            <Create
                title={`${stringExtensions.capitalize(mode)} - ${stringExtensions.capitalize(entity)}`}
                footerButtons={() => (
                    <>
                        <SaveButton {...saveButtonProps} />
                        <CancelButton />
                    </>
                )}
            >
                <Form {...formProps} form={form} layout="vertical" autoComplete="off" scrollToFirstError={true}>
                    <Divider orientation="left">Basic Details</Divider>
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Form.Item
                                label="ID"
                                name="id"
                                tooltip={`Unique ID for ${stringExtensions.capitalize(entity)}.`}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true
                                    },
                                    {
                                        validator: async (_, value: string) => {
                                            if (!value) {
                                                return;
                                            }
                                            const { data } = await checkEntityExists();
                                            if (data && data?.data?.id) {
                                                return Promise.reject(
                                                    new Error(`'${stringExtensions.capitalize(entity)} ID' aleady exists, kindly provide a unique value.`),
                                                );
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                ]}
                            >
                                <Input disabled={mode === "edit"} placeholder={`Provide ${stringExtensions.capitalize(entity)} ID`} onChange={(event) => setEntityId(event.target.value)} />
                            </Form.Item>
                            {
                                entity === RESOURCE_PATH.LOCATION &&
                                <Form.Item
                                    label="Display Timezone"
                                    name="timezone"
                                    tooltip={`Display timezone for ${stringExtensions.capitalize(entity)}.`}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            max: 100
                                        },
                                    ]}
                                >
                                    <Input placeholder={`Display timezone for ${stringExtensions.capitalize(entity)}`} />
                                </Form.Item>
                            }
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Form.Item
                                label="Name"
                                name="name"
                                tooltip={`Name of the ${stringExtensions.capitalize(entity)}.`}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        max: 100
                                    },
                                ]}
                            >
                                <Input placeholder={`Provide ${stringExtensions.capitalize(entity)} Name`} />
                            </Form.Item>
                            {
                                entity === RESOURCE_PATH.LOCATION &&
                                <Form.Item
                                    label="IANA Timezone"
                                    name="timezoneIANA"
                                    tooltip={`Internet Assigned Numbers Authority (IANA) timezone for ${stringExtensions.capitalize(entity)}.`}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            type: "string"
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder={`IANA timezone for ${stringExtensions.capitalize(entity)}`}
                                        showSearch
                                    >
                                        {timezoneList?.sort().map((m, i) => (
                                            <Select.Option value={m} key={i}>{m}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            }
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            {entity === RESOURCE_PATH.LOCATION &&
                                <Form.Item
                                    label="Country"
                                    name="countryId"
                                    rules={[
                                        {
                                            required: true,
                                            type: "string"
                                        },
                                    ]}
                                >
                                    <Select disabled={mode === "edit"} placeholder="Select Country" {...countryProps} allowClear />
                                </Form.Item>
                            }
                        </Col>
                    </Row>
                    <Divider orientation="left">Resourcing Module Configuration</Divider>
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Form.Item
                                label="Resourcing Team Group"
                                name="resourcingTeamGroup"
                                tooltip={`Group alias of the group that is used to manage the resourcing team for this ${stringExtensions.capitalize(entity)}.`}
                                rules={[
                                    {
                                        required: entityRequired,
                                        whitespace: true,
                                        type: "string"
                                    },
                                ]}
                            >
                                <Select placeholder="Search Helium group" {...groupProps} allowClear />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Form.Item
                                label="Resourcing Team DL"
                                name="resourcingTeamDL"
                                tooltip={`Resourcing Team DL used for this ${stringExtensions.capitalize(entity)}.`}
                                rules={[
                                    {
                                        required: entityRequired,
                                        whitespace: true,
                                        type: "email"
                                    }
                                ]}
                            >
                                <Input placeholder="Provide resourcing team distribution list" />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Form.Item
                                label="Resourcing Team Admin"
                                name="resourcingTeamAdmin"
                                tooltip={`Select resourcing team admin who will be managing the resourcing team for this ${stringExtensions.capitalize(entity)}.`}
                                rules={[
                                    {
                                        required: entityRequired,
                                        whitespace: true,
                                        type: "string",
                                    },
                                ]}
                            >
                                <Select placeholder="Select resourcing team admin" {...resourcingAdminProps} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left">Spaces Module Configuration</Divider>
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Form.Item
                                label="Room Administrators"
                                name={["spaces", "administrators"]}
                                tooltip="Start typing to look up"
                                rules={[
                                    {
                                        required: false,
                                        whitespace: true,
                                        type: "array"
                                    },
                                ]}
                            >
                                <Select {...spaceAdminProps}
                                    placeholder="Search Name or Email"
                                    mode="multiple"
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Form.Item
                                label="Power Users"
                                name={["spaces", "powerUsers"]}
                                tooltip="Start typing to look up"
                                rules={[
                                    {
                                        required: false,
                                        whitespace: true,
                                        type: "array"
                                    },
                                ]}
                            >
                                <Select {...userProps}
                                    placeholder="Search Name or Email"
                                    mode="multiple"
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Form.Item
                                label="Power User Group"
                                name={["spaces", "powerUserGroup"]}
                                rules={[
                                    {
                                        required: false,
                                        whitespace: true,
                                        type: "string"
                                    },
                                ]}
                            >
                                <Select {...groupProps}
                                    placeholder="Search Groups"
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Form.Item
                                hidden
                                name={["spaces", "reservationRules", "beginTime"]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                hidden
                                label="End Time"
                                name={["spaces", "reservationRules", "endTime"]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item 
                                label="Reservation Timings" 
                                tooltip={`Configure the begin and end time of reservations being open in this ${stringExtensions.capitalize(entity)} (24 Hour format)`}
                                style={{ marginBottom: 14 }}>
                                <TimePicker placeholder="Begin time" showNow={false}
                                    format={'HH:mm'} placement="bottomLeft" allowClear={true} minuteStep={30}
                                    style={{ marginRight: 20, marginBottom: 10, width: 229 }}
                                    onSelect={(date: Dayjs) => {
                                        form.setFieldValue(["spaces", "reservationRules", "beginTime"], (dayjs(date).format("HH:mm")));
                                    }}
                                />
                                <TimePicker placeholder="End time" showNow={false}
                                    style={{ marginRight: 20, marginBottom: 10, width: 229 }}
                                    format={'HH:mm'} placement="bottomLeft" allowClear={true} minuteStep={30}
                                    onSelect={(date: Dayjs) => {
                                        form.setFieldValue(["spaces", "reservationRules", "endTime"], (dayjs(date).format("HH:mm")));
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Form.Item 
                                label="Reservation Duration"
                                tooltip={`Configure the minimum and maximum reservation duration for this ${stringExtensions.capitalize(entity)} (In Minutes)`}
                                style={{ marginBottom: 14 }}>
                                <Form.Item
                                    noStyle
                                    label="Min Reservation Duration"
                                    name={["spaces", "reservationRules", "minReservationDuration"]}
                                    rules={[
                                        {
                                            type: "number",
                                            min: 15,
                                            max: 60
                                        },
                                    ]}
                                >
                                    <InputNumber placeholder="15 min steps" min={15} max={60} step={15} precision={0} addonBefore="Min"
                                        style={{ marginRight: 20, marginBottom: 10 }}
                                    />
                                </Form.Item>

                                <Form.Item noStyle
                                    name={["spaces", "reservationRules", "maxReservationDuration"]}
                                    label="Max Reservation Duration"
                                    rules={[
                                        {
                                            type: "number",
                                            min: 60,
                                            max: 300
                                        }
                                    ]}
                                >
                                    <InputNumber placeholder="30 min steps" min={60} max={300} step={30} precision={0} addonBefore="Max"
                                        style={{ marginRight: 20, marginBottom: 10 }}
                                    />
                                </Form.Item>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Form.Item label="Align Reservations" style={{ marginBottom: 14 }}>
                                <Form.Item noStyle
                                    name={["spaces", "reservationRules", "alignReservations"]}
                                    rules={[
                                        {
                                            type: "boolean"
                                        }
                                    ]}
                                >
                                    <Radio.Group
                                        optionType="button"
                                        buttonStyle="solid"
                                        style={{ marginRight: 20, marginBottom: 10 }}
                                    >
                                        <Radio key="ar-01" value={true}>Yes</Radio>
                                        <Radio key="ar-02" value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => prevValues.spaces.reservationRules.alignReservations !== currentValues.spaces.reservationRules.alignReservations}
                                >
                                    {({ getFieldValue }) =>
                                        getFieldValue(["spaces", "reservationRules", "alignReservations"]) === true ? (
                                            <>
                                                <Form.Item
                                                    noStyle
                                                    label="Boundary"
                                                    name={["spaces", "reservationRules", "alignmentBoundary"]}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            type: "number",
                                                            min: 0,
                                                            max: 45,
                                                        }
                                                    ]}
                                                >
                                                    <InputNumber style={{ marginRight: 20, marginBottom: 10 }}
                                                        placeholder="Minutes from 0-45" addonBefore="Boundary"
                                                        precision={0} min={0} max={45} step={15} />
                                                </Form.Item>
                                            </>
                                        ) : null
                                    }
                                </Form.Item>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Form.Item
                                label="Advance Days"
                                tooltip={`Configure the number of days in advance that reservations can be made for this ${stringExtensions.capitalize(entity)}.`}
                                name={["spaces", "reservationRules", "advanceDays"]}
                                rules={[{
                                    type: 'number',
                                    min: 1,
                                    max: 30
                                }]}
                            >
                                <InputNumber min={1} max={30} step={1} precision={0} addonAfter="Days" />
                            </Form.Item>

                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}></Col>
                        <Col className="gutter-row" {...threeColumnLayout}></Col>
                    </Row>
                    <Divider orientation="left">Asset Management Module Configuration</Divider>
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Form.Item
                                label="Asset Admins"
                                name="assetAdmin"
                                tooltip={`Select Asset Admins who will be managing the assets for this ${stringExtensions.capitalize(entity)}.`}
                                rules={[
                                    {
                                        required: entityRequired,
                                        whitespace: true,
                                        type: "array",
                                    },
                                ]}
                            >
                                <Select placeholder="Select asset admins" mode="multiple" {...assetAdminProps} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                        </Col>
                    </Row>
                </Form>
            </Create>
        </Spin >
    );
};