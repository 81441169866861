import { arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import {
  Checkbox,
  Create,
  DatePicker,
  Form, Input, Radio, SaveButton, Select, Spin, Tag, useCheckboxGroup, useForm, useSelect
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useMany, useOne } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";
import dayjs from "dayjs";
import { ILocations, ILookup, IRole, IUser, IUserMetadata } from "interfaces";
import { UserType } from "interfaces/enum";
import { useState } from "react";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, saveButtonProps } = useForm<IUser>({
    dataProviderName: DATAPROVIDER_CREATE,
    redirect: "list"
  });
  const { data: userData } = useGetIdentity();
  const record = userData as IUser;

  const { data: currentUserRoleLevels } = useMany<IRole>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.ROLE,
    ids: record?.roleIds,
    queryOptions: {
      enabled: record?.roleIds.length > 0,
    },
  });

  const currentLevels = currentUserRoleLevels ? (currentUserRoleLevels.data.map(r => r.level ?? 0) as number[]) : [-10];
  const currentUserMax = Array.isArray(currentLevels) ? Math.max(...currentLevels) : -10;
  const [userType, setUserType] = useState<UserType | undefined>(undefined);
  const [id, setEmail] = useState("");
  const [userCountry, setUserCountry] = useState(formProps?.initialValues?.countryId)
  if (userCountry === undefined && formProps.initialValues?.countryId !== undefined) {
    setUserCountry(formProps.initialValues.countryId)
  }

  const { queryResult: roleResult, } = useCheckboxGroup<IRole>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.ROLE,
    optionLabel: "name",
    optionValue: "id",
    sort: [{
      field: "level",
      order: "asc"
    },
    ],
    filters: [{
      field: "level",
      operator: "lte",
      value: currentUserMax
    },
    {
      field: "applicableUserTypes ARRAY_CONTAINS",
      operator: "eq",
      value: userType
    }],
    fetchSize: 50,
    queryOptions: {
      enabled: !!userType
    }
  });

  const { refetch: checkEmail } = useOne<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    id: id,
    queryOptions: {
      enabled: false,
    }
  });

  const { data: entityMetadata, isLoading: metadataLoading } = useOne<IUserMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.USERS,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });

  const metaConfig = entityMetadata?.data?.config;

  const { selectProps: managerProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "nameEmail",
    optionValue: "id",
    filters: [{
      field: "id",
      operator: "ne",
      value: id
    }],
    sort: [{
      field: "lastAccessedAt",
      order: "desc"
    }],
    queryOptions: {
      enabled: userType === UserType.Employee
    }
  });
  const { selectProps: countryProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.COUNTRY,
    optionLabel: "name",
    optionValue: "id",
    sort: [
      {
        field: "name",
        order: "asc"
      }
    ],
    fetchSize: 100
  });
  const { queryResult: locationProps } = useSelect<ILocations>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.LOCATION,
    optionLabel: "name",
    optionValue: "id",
    sort: [
      {
        field: "name",
        order: "asc"
      }
    ],
    fetchSize: 100
  });

  const groupedRole = arrayExtensions.groupBy((roleResult.data?.data ?? []).map(r => {
    r.groupProp = r.ui.color;
    return r;
  }), "groupProp");

  const todaysDate = dayjs().add(1, "day");
  let locations = locationProps.data?.data
  
  return (
    <Spin spinning={locationProps.isLoading || metadataLoading}>
      <Create
        footerButtons={() => (
          <>
            <SaveButton {...saveButtonProps} />
            <CancelButton />
          </>
        )}
      >
        <Form {...formProps} layout="vertical" autoComplete="off"
          initialValues={{ employee: { screeningType: "HireRight" } }}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                whitespace: true,
                max: 100
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="id"
            rules={[
              {
                required: true,
                type: "email",
                whitespace: true,
                min: 5,
              },
              {
                validator: async (_, value: string) => {
                  if (!value) {
                    return;
                  }

                  // vaidate domain
                  if (!entityMetadata) {
                    return;
                  }

                  if ((metaConfig?.loginDomains ?? []).some(e => value.endsWith(e))) {
                    const { data } = await checkEmail();
                    if (data && data?.data?.id) {
                      return Promise.reject(
                        new Error("'Email' belongs to '" + data?.data?.name + "'. It must be unique"),
                      );
                    }
                    return Promise.resolve();
                  }
                  else {
                    return Promise.reject(
                      new Error("'Email' must belong to any of these domains - " + (metaConfig?.loginDomains ?? []).join(", ")),
                    );
                  }
                }
              }
            ]}
          >
            <Input onChange={(event) => setEmail(event.target.value)} />
          </Form.Item>

          <Form.Item
            label="User Type"
            name="userType"
            rules={[
              {
                required: true,
                whitespace: true
              },
            ]}
          >
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              options={metaConfig?.userTypes.map(ut => ({
                label: stringExtensions.capitalize(ut.toString()),
                value: ut.toString()
              }))}
              onChange={(event) => {
                setUserType(event.target.value);
              }}
            />
          </Form.Item>

          {(roleResult?.data?.data?.length ?? 0) > 0 &&
            <Form.Item
              label="Roles"
              name="roleIds"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  type: "array"
                },
              ]}
            >
              <Checkbox.Group style={{ display: "block" }}>
                {
                  Object.keys(groupedRole).map((k, ind) =>
                    <div key={ind}>
                      {(groupedRole[k] as IRole[]).map((r, i) => (
                        <Checkbox key={i} value={r.id} style={{ marginLeft: 0 }} className="role-checkbox" >
                          <Tag color={r.ui?.color || "blue"}>{r.name || ""}</Tag>
                        </Checkbox>
                      ))}
                    </div>)
                }
              </Checkbox.Group>
            </Form.Item>
          }

          {userType === UserType.Employee &&
            <>
              <Form.Item
                label="TCS Manager"
                name="managerUserId"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                  },
                ]}
              >
                <Select placeholder="Search Name or Email" {...managerProps} allowClear />
              </Form.Item>
              <Form.Item
                label="Country"
                name="countryId"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Select Country"
                  showSearch
                  {...countryProps}
                  onChange={function (val) { setUserCountry(val); form?.setFieldsValue({ 'locationId': null }) }}
                >
                </Select>
              </Form.Item>
              <Form.Item
                label="Location"
                name="locationId"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder={form?.getFieldValue('countryId') ? "Select Location" : "Select a Country first"}
                >
                  {locations?.filter(loc => loc.countryId === userCountry).map((m, i) => (
                    <Select.Option value={m.id} key={`lc-${i}`}>{m.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Screening Type"
                name={["employee", "screeningType"]}
                rules={[
                  {
                    required: false,
                    whitespace: true,
                  },
                ]}
              >
                <Input disabled />
                {/* <Input defaultValue="HireRight" value="HireRight" disabled /> */}
                {/* <Radio.Group buttonStyle="solid" defaultValue="HireRight">
                <Radio.Button value="HireRight" checked>HireRight</Radio.Button>
              </Radio.Group> */}
              </Form.Item>
              <Form.Item
                label="Screening Id"
                name={["employee", "screeningId"]}
                tooltip={"Provide BGC Screening Id"}
                rules={[
                  {
                    required: false,
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Screening Date"
                name={["employee", "screeningDate"]}
                tooltip="BGC Screening completion date"
                rules={[
                  {
                    required: false,
                    whitespace: true,
                    type: "date"
                  },
                ]}
              >
                <DatePicker picker="date" style={{ width: 280 }}
                  disabledDate={(selDate) => selDate && selDate > todaysDate} />
              </Form.Item>
              <Form.Item
                label="Hire Source"
                name={["employee", "hireSource"]}
                rules={[
                  {
                    required: false,
                    whitespace: true,
                  },
                ]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="Local">Local</Radio.Button>
                  <Radio.Button value="Expat">Expat</Radio.Button>
                  <Radio.Button value="Trainee">Trainee</Radio.Button>
                  <Radio.Button value="BA">BA</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </>
          }
        </Form>
      </Create>
    </Spin>
  );
};
