import { Alert, AntdList, Button, Card, DateField, DatePicker, Divider, Drawer, EditButton, Icons, List, Tag, Tooltip, Typography, useSimpleList } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, LogicalFilter, useGetIdentity, useOne } from "@pankod/refine-core";
import { RenderSlots } from "components/spaces/renderSlots";
import { ReservationLegendTags } from "components/spaces/reservationLegendTags";
import { RoomFilter } from "components/spaces/roomFilter";
import { RenderLink } from "components/utils/renderLink";
import dayjs from "dayjs";
import { IAvailFilterVariables, IAvailability, IRoomMetadata, IUser } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_SEARCH, RESOURCE_PATH } from "scripts/site";

export const AvailabilityList: React.FC<IResourceComponentsProps> = () => {

    const { data: userData, isLoading: userLoading } = useGetIdentity();
    const userObj = userData as IUser;

    const { listProps, queryResult: dataList, searchFormProps, filters: existingFilters } = useSimpleList<IAvailability, HttpError, IAvailFilterVariables>({
        dataProviderName: DATAPROVIDER_SEARCH,
        resource: RESOURCE_PATH.ROOM,
        initialPageSize: 5,
        initialFilter: [{
            field: "_searchDates",
            operator: "eq",
            value: dayjs().format("YYYY-MM-DD")
        }],
        initialSorter: [
            {
                field: "updatedAt",
                order: "desc"
            },
        ],
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { q, zoneId, buildingId, occupancy, offline, privateRoom, duration, date } = params;
            filters.push({
                field: "q",
                operator: "contains",
                value: q
            });
            filters.push({
                field: "zoneId",
                operator: "eq",
                value: zoneId
            });
            filters.push({
                field: "buildingId",
                operator: "eq",
                value: buildingId
            });
            filters.push({
                field: "maxOccupancy",
                operator: "gte",
                value: occupancy
            });
            filters.push({
                field: "offline",
                operator: "eq",
                value: offline
            });
            filters.push({
                field: "private",
                operator: "eq",
                value: privateRoom
            });
            filters.push({
                field: "_duration",
                operator: "eq",
                value: duration
            });
            filters.push({
                field: "_searchDates",
                operator: "eq",
                value: date ?? dayjs().format("YYYY-MM-DD")
            });

            setSearchDate(date);
            return filters;
        }
    });

    const [searchDate, setSearchDate] = useState(dayjs().format("YYYY-MM-DD"));

    const { data: entityMetadata } = useOne<IRoomMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.ROOM,
        queryOptions: {
            enabled: true,
            staleTime: 300000
        }
    });
    const metaConfig = entityMetadata?.data?.config;
    const [filterOpen, switchFilterForm] = useState(false);

    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const dateInFilter = existingFilters?.find(filter => (filter as LogicalFilter).field === "_searchDates")?.value;
    if (isInitialLoad && dateInFilter) {
        const dateToSet = dayjs(dateInFilter).format("YYYY-MM-DD");
        setIsInitialLoad(false);
        setSearchDate(dateToSet);
        searchFormProps.form?.setFieldValue("date", dateToSet);
    }

    return (
        <List
            headerProps={{
                title: "Search Availability",
                extra: <>
                    <ReservationLegendTags availability={true}></ReservationLegendTags>
                </>
            }}
        >
            {(!userLoading && ((!userObj?.countryId) || userObj?.countryId === null || userObj?.countryId === "")) &&
                <Alert
                    message="Country is not assigned"
                    description={<>Please <EditButton style={{ padding: 0 }} type="link" icon={false} resourceNameOrRouteName={RESOURCE_PATH.PROFILE} recordItemId={userObj?.id}>update your profile</EditButton> and select your country.</>}
                    type="warning"
                    showIcon
                    style={{ marginBottom: 20 }}
                />
            }

            <Card className="searchAvailability"
                title={<DatePicker
                    allowClear={false}
                    value={dayjs(searchDate)}
                    disabledDate={(current) => {
                        return current && current < dayjs().startOf('day');
                    }}
                    onChange={(selectedDate) => {
                        setSearchDate(selectedDate?.format("YYYY-MM-DD") ?? "");
                        searchFormProps.form?.setFieldValue("date", selectedDate?.format("YYYY-MM-DD"))
                        searchFormProps.form?.submit();
                    }}
                />}
                extra={<>
                    <Button key="filter" icon={<Icons.SearchOutlined />} onClick={() => switchFilterForm(true)}>{metaConfig?.search?.textMessages?.buttonText || "Search & Filter"}</Button>
                </>}>
            </Card>

            <AntdList itemLayout="vertical"
                {...listProps}
                loading={dataList.isRefetching || dataList.isLoading}
                pagination={{
                    ...listProps.pagination,
                    position: "bottom",
                    showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }}>Total {total}</Typography.Title>)
                }}
                renderItem={(item, index) => (
                    <AntdList.Item key={index} style={{ border: 0, padding: 5 }}>
                        <Card size="small" className="availability" title={<>
                            <RenderLink style={{ fontSize: 16 }} id={item?.room.id || ""} name={item.room.name} resource={RESOURCE_PATH.ROOM}></RenderLink>
                            <Divider type="vertical"></Divider>
                            <DateField type="secondary" format="ll" value={item.date} style={{ marginLeft: 10 }} />
                            {item.timezone && <><Divider type="vertical"></Divider><Tag color="cyan">{item.timezone}</Tag></>}
                        </>}
                            extra={<>
                                {item?.room?.private === true &&
                                    <>
                                        <Tooltip title="Only Admins can book">
                                            <Tag color="warning">Private</Tag>
                                        </Tooltip>
                                        <Divider type="vertical"></Divider>
                                    </>
                                }
                                <Tooltip title="Occupancy">
                                    <Tag icon={<Icons.UserOutlined></Icons.UserOutlined>}>
                                        {(item.room?.minOccupancy ?? 0) + " (Min) - " + (item.room?.maxOccupancy ?? 0) + " (Max)"}
                                    </Tag>
                                </Tooltip>
                            </>}
                        >
                            <RenderSlots {...item}></RenderSlots>
                        </Card>
                    </AntdList.Item>
                )}
            >
            </AntdList>
            <Drawer
                title={metaConfig?.search?.textMessages?.drawerFormHeader || "Search & Filter"}
                placement="right"
                size="default"
                open={filterOpen}
                forceRender={true}
                onClose={() => { switchFilterForm(false) }}
            >
                <RoomFilter formProps={searchFormProps} availability={true} />
            </Drawer>
        </List >
    );
}



// gantt
//     title A Gantt Diagram
//     dateFormat HH:mm
//     axisFormat %H:%M

//     section Section
//     A task           :done, 01:00, 60m
//     Another task     :active, 04:00, 120m

//     section Another
//     Task in sec      :crit, 01:00, 90m
//     another task     : 150m