import { ButtonProps, Create, Drawer, DrawerProps, Form, FormProps, Grid, Radio, Typography } from "@pankod/refine-antd";
import { IEvaluationModel } from "interfaces";

type CreateAssessmentProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  engagementId: string;
  evaluationModelList: IEvaluationModel[]
};

export const CreateAssessment: React.FC<CreateAssessmentProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
  engagementId,
  evaluationModelList
}) => {
  
  const breakpoint = Grid.useBreakpoint();
  const models: any = [];
  evaluationModelList.forEach(md  => {
    models.push(<Radio.Button key={md.id} value={md.id}>{md.name}</Radio.Button>);
  });

  return (
    <Drawer getContainer={false}
      {...drawerProps}
      width={breakpoint.sm ? "700px" : "100%"}
    >
      <Create saveButtonProps={saveButtonProps}
        headerProps={{
          backIcon: false,
          title: "Add Assessment",
          tags: <></>
        }}
      >
        <Form {...formProps}

          initialValues={{ engagementId: engagementId }}
          layout="vertical">

          <Form.Item
            hidden
            label="Engagement"
            name="engagementId"
          >
            <Typography.Text>{engagementId}</Typography.Text>
          </Form.Item>
          <Form.Item
            label="Evaluation Model"
            name="evaluationModelId"
            rules={[
              {
                required: true,
                whitespace: true
              },
            ]}
          >
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
            >
              {models}
            </Radio.Group>
          </Form.Item>
        </Form>
      </Create>
    </Drawer >
  );
};
