import { arrayExtensions } from "@architecture-innovation-transformation/lib-common";
import { Divider, Edit, Form, Input, Radio, SaveButton, Icons, useForm, DatePicker, Tag, Select, useSelect, Space, DeleteButton, ListButton, RefreshButton } from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useOne } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { CancelButton } from "components/utils/cancelButton";
import { StateButtons } from "components/common";
import dayjs from "dayjs";
import { IBaseProps, IEvent, IEventMetadata, ILookup, IUser } from "interfaces";
import { DATAPROVIDER_DELETE, DATAPROVIDER_LOOKUP, DATAPROVIDER_UPDATE, parseTimeZone, RESOURCE_PATH } from "scripts/site";
import MDEditor from "@uiw/react-md-editor";
import { getAppTheme } from "scripts/theme";

export const EventEdit: React.FC<IResourceComponentsProps> = () => {

  const { data: userData } = useGetIdentity();
  const userObj = userData as IUser;

  const { formProps, saveButtonProps, queryResult } = useForm<IEvent>({
    dataProviderName: DATAPROVIDER_UPDATE,
  });

  const { data: metadata } = useOne<IEventMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.EVENT,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });

  const { selectProps: usersList } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "nameEmail",
    optionValue: "id",
    filters: [{
      field: "id",
      operator: "ne",
      value: userObj.id
    }],
    sort: [{
      field: "lastAccessedAt",
      order: "desc"
    }]
  });

  const metaConfig = metadata?.data.config;
  const record = queryResult?.data?.data;

  return (
    <Edit
      isLoading={queryResult?.isFetching}
      title={`Edit - ${queryResult?.isFetching ? "" : record?.name}`}
      headerButtons={() => (
        queryResult?.isFetching ? "" :
          <>
            {(record?.stateManager.state === "draft" &&
              ((record?.eventOrganizer === userObj.id) || record?.eventOrganizingTeam.find(x => x === userObj.id))) ?
              <DeleteButton dataProviderName={DATAPROVIDER_DELETE} />
              : ""}
            <ListButton></ListButton>
            <RefreshButton></RefreshButton>
          </>
      )}
      footerButtons={() => (
        queryResult?.isFetching ? "" :
          <>
            <StateButtons {...record as IBaseProps}
              resourceToAct={RESOURCE_PATH.EVENT}
              resourceToActId={record?.id || "0"}
              resourceToNavigate={RESOURCE_PATH.EVENT}
              hideActions={['submit', 'approve']}
            />
            <SaveButton {...saveButtonProps} />
            <CancelButton />
            {(record?.stateManager.state === "draft" &&
              ((record?.eventOrganizer === userObj.id) || record?.eventOrganizingTeam.find(x => x === userObj.id))) ?
              <DeleteButton dataProviderName={DATAPROVIDER_DELETE}/>
              : ""}
          </>
      )}>
      <Form {...formProps} autoComplete="off"
        layout="vertical"
      >
        <Divider orientation="left">What</Divider>
        <Form.Item
          label="Event Title"
          name="name"
          tooltip="Title for the event"
          rules={[
            {
              required: true,
              whitespace: true,
              min: 5,
              max: 100
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Event Description"
          name="description"
          tooltip="Short blurb describing the purpose, audience and benefit of event."
          rules={[
            {
              required: true,
              whitespace: true,
              min: 15,
              max: 200
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="Describe the event outlining purpose, intended audience and benefits" />
        </Form.Item>
        <Form.Item
          label="Target Audience"
          name="eventAudience"
          rules={[
            {
              required: true
            },
          ]}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={metaConfig?.eventAudience.sort()}
          />
        </Form.Item>
        <Form.Item
          label="Event Category"
          name="eventCategory"
          rules={[
            {
              required: true
            },
          ]}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={metaConfig?.eventCategory.sort()}
          />
        </Form.Item>
        <Divider orientation="left">When</Divider>
        <Form.Item
          label="Event Schedule"
          name="eventSchedule"
          tooltip="Select time as per local timezone."
          rules={[
            {
              required: true
            }
          ]}
        >
          <DatePicker.RangePicker
            format="YYYY-MM-DD hh:mm A"
            disabledDate={(current) => {
              return current && current.valueOf() < Date.now();
            }}
            defaultValue={[dayjs(record?.eventStartTime), dayjs(record?.eventEndTime)]}
            showTime={true}
            minuteStep={30}
            suffixIcon={<Icons.DownOutlined />}
            allowClear={false}
            renderExtraFooter={() => `Local Time Zone`}
            onChange={(value) => {
              if (value) {
                formProps.form?.setFieldsValue({
                  eventStartTime: value[0],
                  eventEndTime: value[1],
                  eventSchedule: [value[0], value[1]]
                })
              } else {
                formProps.form?.setFieldsValue({
                  eventStartTime: null,
                  eventEndTime: null,
                  eventSchedule: null
                })
              }
            }}
          />
          <Tag color="cyan" style={{ marginLeft: "15px" }}>
            {parseTimeZone(userObj?.userTime)}
          </Tag>
        </Form.Item>
        <Form.Item
          name="eventStartTime"
          hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item
          name="eventEndTime"
          hidden={true}>
          <Input />
        </Form.Item>
        <Divider orientation="left">Where</Divider>
        <Form.Item
          label="Event Delivery Mode"
          name="eventMode"
          rules={[
            {
              required: true
            },
          ]}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={metaConfig?.eventMode.sort()}
          />
        </Form.Item>
        <Form.Item
          label="Joining details of event"
          name="eventJoiningBlurb"
          tooltip="Blurb outlining on how to join the event, including meeting links, instructions, prerequisutes etc. "
          rules={[
            {
              required: true
            },
          ]}
        >
          <MDEditor data-color-mode={getAppTheme()} />
        </Form.Item>
        <Divider orientation="left">Event Organized By</Divider>
        <Form.Item
          label="Event Organizer"
          name="eventOrganizer"
        >
          <ShowUserCard id={record?.eventOrganizer} ></ShowUserCard>
        </Form.Item>
        {record?.eventOrganizer === userObj?.id ?
          <>
            <Form.Item
              label="Organizing Team"
              name="eventOrganizingTeam"
              tooltip="Add/Remove name of associates from organzing team"
              rules={[
                {
                  required: false,
                  whitespace: true,
                  type: "array"
                }
              ]}
            >
              <Select placeholder="Search Name or Email" mode="multiple" {...usersList} allowClear />
            </Form.Item>
          </>
          :
          <>
            <Form.Item
              label="Organizing Team"
              name="eventOrganizingTeam"
              valuePropName="children"
            >
              <Space wrap split={<Divider type="vertical" />} >
                {arrayExtensions.validateArray(record?.eventOrganizingTeam ?? []) ? record?.eventOrganizingTeam?.map((e, i) => <ShowUserCard key={`org-team-${i}`} id={e}></ShowUserCard>) : "None"}
              </Space>
            </Form.Item>
            <Tag color={"orange"}>You are event contributor for this event. In case you want to remove/add more contributors, please reach out to event Organizer.</Tag>
          </>
        }
      </Form>
    </Edit>
  );
};