import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import {
  Button, Create, Form, Icons, Input, Radio, SaveButton, Select, Typography, useForm, useSelect
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useOne } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";
import { IGroupQuery, IGroupsMetadata, IListData, ILookup, IMemberGroup, IUser } from "interfaces";
import { GroupType, UserType } from "interfaces/enum";
import { useState } from "react";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, RESOURCE_PATH, SELECTION_LISTS } from "scripts/site";

export const GroupCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, form } = useForm<IMemberGroup>({
    dataProviderName: DATAPROVIDER_CREATE,
    redirect: "list"
  });
  const [id, setGroupId] = useState("");

  const { refetch: checkGroupExists } = useOne<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.GROUPS,
    id: id,
    queryOptions: {
      enabled: false,
    }
  });

  const { selectProps: userProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "nameEmail",
    optionValue: "id",
    sort: [{
      field: "nameEmail",
      order: "asc"
    }],
    filters: [
      {
        field: "stateManager.state",
        operator: "eq",
        value: "active"
      }
    ],
    pagination: {
      pageSize: 200,
    }
  });

  const [groupType, setGroupType] = useState("");
  const [propertyList, setPropertyList] = useState<IGroupQuery[]>([]);

  const updateState = (index: number, groupQuery: IGroupQuery) => {
    // If array already contains item at that index
    if (index < propertyList.length) {
      setPropertyList(propertyList => {
        return propertyList.map((item, j) => {
          return j === index ? groupQuery : item;
        })
      })
    } else if (index === 0) {
      setPropertyList([groupQuery]);
    }
    else {
      setPropertyList([...propertyList, groupQuery]);
    }
    let value = form.getFieldValue("dynamicRule")
    value[index] = { ...value[index], 'operator': null, 'value': null }
    form.setFieldsValue({ 'dynamicRule': value })
  }

  const { data: userData } = useGetIdentity();
  const userObj = userData as IUser;

  const { data: groupsMetadata } = useOne<IGroupsMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.GROUPS,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });

  const { data: hobbiesList } = useOne<IListData>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.LIST,
    id: SELECTION_LISTS.HOBBIES,
    queryOptions: {
      enabled: groupType === GroupType.Dynamic,
      staleTime: 300000
    }
  });

  const { data: technicalExpertiseList } = useOne<IListData>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.LIST,
    id: SELECTION_LISTS.TECHNICAL_EXPERTISE,
    queryOptions: {
      enabled: groupType === GroupType.Dynamic,
      staleTime: 300000
    }
  });

  const { data: functionalExpertiseList } = useOne<IListData>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.LIST,
    id: SELECTION_LISTS.FUNCTIONAL_EXPERTISE,
    queryOptions: {
      enabled: groupType === GroupType.Dynamic,
      staleTime: 300000
    }
  });

  const { selectProps: roleProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.ROLE,
    optionLabel: "name",
    optionValue: "id",
    queryOptions: {
      enabled: groupType === GroupType.Dynamic,
      staleTime: 300000
    }
  });

  const { selectProps: countryProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.COUNTRY,
    optionLabel: "name",
    optionValue: "id",
    queryOptions: {
      enabled: groupType === GroupType.Dynamic,
      staleTime: 300000
    },
    fetchSize: 100
  });

  const { selectProps: locationProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.LOCATION,
    optionLabel: "name",
    optionValue: "id",
    queryOptions: {
      enabled: groupType === GroupType.Dynamic,
      staleTime: 300000
    },
    fetchSize: 100
  });

  const metaConfig = groupsMetadata?.data?.config;

  const getValueList = (listName: string) => {
    if (listName === SELECTION_LISTS.HOBBIES) {
      return hobbiesList;
    } else if (listName === SELECTION_LISTS.TECHNICAL_EXPERTISE) {
      return technicalExpertiseList;
    } else if (listName === SELECTION_LISTS.FUNCTIONAL_EXPERTISE) {
      return functionalExpertiseList;
    } else {
      return null;
    }
  }

  const getEntityValueList = (listName: string) => {
    if (listName === RESOURCE_PATH.ROLE) {
      return roleProps;
    }
    else if (listName === RESOURCE_PATH.LOCATION) {
      return locationProps;
    }
    else if (listName === RESOURCE_PATH.COUNTRY) {
      return countryProps;
    }
    else if (listName === "userType") {
      let userTypeProps = Object.entries(UserType).filter(entry => !parseInt(entry[0])).map(entry => ({
        label: stringExtensions.capitalize(entry[0].toString()),
        value: entry[1]
      }));
      return {
        options: userTypeProps,
        loading: false,
        showSearch: false,
        filterOption: false
      };
    }
    else {
      return null;
    }
  }

  return (
    <Create
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} />
          <CancelButton />
        </>
      )}
    >
      <Form
        {...formProps}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          label="Group Identifier (Alias)"
          name="id"
          tooltip="A short alias for group, alias needs to be unique across the system. It should be a combination of alphabets and numbers with Hyphen(-) and underscore(_) as supported special characters."
          rules={[
            {
              required: true,
              whitespace: true
            },
            {
              pattern: new RegExp(/^[A-Za-z0-9_-]+$/),
              message: "Group Identifier should be a combination of alphabets and numbers with Hyphen(-) and underscore(_) as supported special characters."
            },
            {
              validator: async (_, value: string) => {
                if (!value) {
                  return;
                }
                const { data } = await checkGroupExists();
                if (data && data?.data?.id) {
                  return Promise.reject(
                    new Error("'Group' alias already taken up by group with name '" + data?.data?.name + "'. Group alias must be unique"),
                  );
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input minLength={5} maxLength={50}
            placeholder="Provide a short alias for group"
            onChange={(event) => setGroupId(event.target.value)} />
        </Form.Item>

        <Form.Item
          label="Group description"
          name="name"
          tooltip="Short abstract outlining the purpose of the group."
          rules={[
            {
              required: true,
              whitespace: true,
              min: 10,
              max: 100
            },
          ]}
        >
          <Input placeholder="Provide a short description" />
        </Form.Item>

        <Form.Item
          label="Select Group Admin(s)"
          name="admins"
          tooltip="Users responsible for group management"
          initialValue={[userObj?.id]}
          rules={[
            {
              required: true,
              whitespace: true,
              type: "array",
              min: 1,
              max: 3,
              message: "Minimum 1 and maximum of 3 admins are supported for a group."
            },
          ]}
        >
          <Select placeholder="Search Name or Email" mode="multiple" {...userProps} allowClear />
        </Form.Item>

        <Form.Item
          label="Enable Delete Lock"
          name="CanNotDelete"
          tooltip="Enabling this option will add a lock on group which will prevent any accidental deletion."
          initialValue={false}
          rules={[
            {
              type: "boolean",
              required: true
            }
          ]}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            name="CanNotDelete"
          >
            <Radio key="cd-01" value={true}>Yes</Radio>
            <Radio key="cd-02" value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.CanNotDelete !== currentValues.CanNotDelete}
        >
          {({ getFieldValue }) =>
            getFieldValue('CanNotDelete') === true ? (
              <>
                <Form.Item
                  label="Comments"
                  name="notes"
                  tooltip="Outline the purpose of the lock. This field documents the need for, and importance of lock placed."
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      max: 200
                    },
                  ]}
                >
                  <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} placeholder="Provide the comments why the lock is been added." />
                </Form.Item>
              </>
            ) : null
          }
        </Form.Item>

        <Form.Item
          label="Group Type"
          name="groupType"
          initialValue={groupType}
          rules={[
            {
              required: true,
              whitespace: true
            },
          ]}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={["static", "dynamic"].map(ut => ({
              label: stringExtensions.capitalize(ut.toString()),
              value: ut.toString()
            }))}
            onChange={(event) => {
              setGroupType(event.target.value);
            }}
          />
        </Form.Item>

        {groupType === GroupType.Static &&
          <>
            <Form.Item
              label="Add Members"
              name="members"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  type: "array",
                  min: 1,
                  max: 150,
                  message: "Maximum of 150 members are supported"
                },
              ]}
            >
              <Select
                placeholder="Search Name or Email to add members"
                mode="multiple"
                {...userProps}
                allowClear />
            </Form.Item>
          </>
        }

        {groupType === GroupType.Dynamic &&
          <>
            <Typography.Title level={5}>Configure Membership Rules</Typography.Title>
            <Form.List
              name="dynamicRule"
              rules={[
                {
                  validator: async (_, dynamicRule) => {
                    if (!dynamicRule || dynamicRule.length < 1) {
                      return Promise.reject(new Error('Minimum 1 rule configuration is required'));
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      required={false}
                      key={field.key}
                    >
                      <Form.Item
                        name={[index, "AndOr"]}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: index > 0 ? true : false,
                            whitespace: true,
                            message: "Please select the And/Or clause",
                          },
                        ]}
                        noStyle
                      >
                        <Select
                          style={{ width: "10%", marginRight: 6 }}
                          disabled={index === 0 ? true : false}
                          placeholder="Clause"
                        >
                          {["and", "or"].map((ut, i) => (
                            <Select.Option value={ut.toString()} key={`andor-${i}`}>{stringExtensions.capitalize(ut.toString())}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={[index, "field"]}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Property is required.",
                          }
                        ]}
                        noStyle
                      >
                        <Select
                          placeholder="Choose a Property"
                          style={{ width: "15%", marginRight: 6 }}
                          onSelect={(val: any) => {
                            let selectedValue = metaConfig?.queryFields.find(x => x.field === val);
                            if (selectedValue) {
                              updateState(index, selectedValue);
                            }
                          }
                          }
                        >
                          {metaConfig?.queryFields.sort().map((m, i) => (
                            <Select.Option value={m.field} key={i}>{m.label}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name={[index, "operator"]}
                        validateTrigger={['onChange', 'onBlur']}
                        dependencies={[index, 'field']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Operator is required",
                          },
                        ]}
                        noStyle
                      >
                        <Select
                          placeholder="Choose an Operator"
                          style={{ width: "15%", marginRight: 6 }}
                        >
                          {propertyList[index]?.operators?.sort().map((m, i) => (
                            <Select.Option value={m.operator} key={i}>{m.label}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        required={false}
                        key={`${field.key}-${index}-valfld`}
                        noStyle
                      >
                        {propertyList[index]?.fieldType === 'string' &&
                          <Form.Item
                            name={[index, "value"]}
                            validateTrigger={['onChange', 'onBlur']}
                            dependencies={[index, 'field']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Value is required",
                              },
                            ]}
                            noStyle
                          >
                            <Input placeholder="Provide the value of property to check" style={{ width: '50%' }} />
                          </Form.Item>
                        }
                        {propertyList[index]?.fieldType === 'boolean' &&
                          <Form.Item
                            name={[index, "value"]}
                            validateTrigger={['onChange', 'onBlur']}
                            dependencies={[index, 'field']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Value is required",
                              },
                            ]}
                            noStyle
                          >
                            <Select
                              placeholder="Select the value"
                              style={{ width: '50%' }}
                            >
                              {["true", "false"].map((m, i) => (
                                <Select.Option value={m} key={i}>{stringExtensions.capitalize(m)}</Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        }
                        {propertyList[index]?.fieldType === 'array' && getValueList(propertyList[index]?.arrayListName) &&
                          <Form.Item
                            name={[index, "value"]}
                            validateTrigger={['onChange', 'onBlur']}
                            dependencies={[index, 'field']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Value is required",
                              },
                            ]}
                            noStyle
                          >
                            <Select
                              placeholder="Select the value"
                              style={{ width: '50%' }}
                            >
                              {getValueList(propertyList[index]?.arrayListName)?.data?.data.map((m, i) => (
                                <Select.Option value={m} key={i}>{stringExtensions.capitalize(m)}</Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        }
                        {propertyList[index]?.fieldType === 'entityarray' && getEntityValueList(propertyList[index]?.arrayListName) &&
                          <Form.Item
                            name={[index, "value"]}
                            validateTrigger={['onChange', 'onBlur']}
                            dependencies={[index, 'field']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Value is required",
                              },
                            ]}
                            noStyle
                          >
                            <Select
                              placeholder="Select the value"
                              style={{ width: '50%' }}
                              {...getEntityValueList(propertyList[index]?.arrayListName)}
                            >
                            </Select>
                          </Form.Item>
                        }

                      </Form.Item>
                      {
                        fields.length > 1 ? (
                          <Icons.MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                            title="Remove Rule"
                          />
                        ) : null
                      }
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: '60%' }}
                      icon={<Icons.PlusOutlined />}
                    >
                      Add Rule
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item
              label="Users to be included to group"
              name="includeMembers"
              tooltip={<span>Search and Select the name of associate(s) to be <b>included</b> <i>specifically</i> to this group's membership.</span>}
              rules={[
                {
                  required: false,
                  whitespace: true,
                  type: "array"
                },
              ]}
            >
              <Select placeholder="Search Name or Email" mode="multiple" {...userProps} allowClear />
            </Form.Item>
            <Form.Item
              label="Users to be excluded from group"
              name="excludeMembers"
              tooltip={<span>Search and Select the name of associate(s) to be <b>excluded</b> <i>specifically</i> from this group's membership.</span>}
              rules={[
                {
                  required: false,
                  whitespace: true,
                  type: "array"
                },
              ]}
            >
              <Select placeholder="Search Name or Email" mode="multiple" {...userProps} allowClear />
            </Form.Item>
          </>
        }
      </Form>
    </Create>
  );
};