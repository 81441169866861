import { Tabs } from "@pankod/refine-antd";
import { HistoryTable } from "components/common/historyTable";
import { TimelineView } from "components/common/timelineView";
import { IStateManager } from "interfaces";

type TabPosition = 'left' | 'right' | 'top' | 'bottom';
interface IHistoryTab {
    stateManager: IStateManager,
    tabPosition: TabPosition,
    modalView?: boolean
}

export const HistoryTab: React.FC<IHistoryTab> = ({ stateManager, tabPosition = 'top', modalView = false }) => {

    let restrictHeight: string = modalView ? "488px" : "auto"

    return (
        <>
            <Tabs
                tabPosition={tabPosition}
                items={[
                    {
                        label: "Table View",
                        key: "tableViewTab",
                        forceRender: true,
                        style: { height: restrictHeight },
                        children: <HistoryTable {...stateManager} key="historyTable" />
                    },
                    {
                        label: "Timeline View",
                        key: "timelineViewTab",
                        forceRender: true,
                        style: { overflowY: "auto", overflowX: 'hidden', height: restrictHeight },
                        children: <TimelineView {...stateManager} key="timelineView" />
                    }

                ]}
            />
        </>
    );
};