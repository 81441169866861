import { arrayExtensions } from "@architecture-innovation-transformation/lib-common";
import { AntdList, Button, Divider, Form, Input, Space, Typography, message } from "@pankod/refine-antd";
import { useUpdate } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import dayjs from "dayjs";
import { IComment, ISocial } from "interfaces/article";
import { useState } from "react";
import { DATAPROVIDER_SOCIAL, RESOURCE_PATH } from "scripts/site";

export declare type commentsProps = {
    comments?: IComment[];
    socialElementId: string;
};

export const SocialComments: React.FC<commentsProps> = ({
    comments,
    socialElementId
}) => {

    const [form] = Form.useForm<{ comment: string }>();
    const { mutate } = useUpdate<ISocial>();
    const [disableForm, setDisableForm] = useState<boolean>(false);

    const handleComment = () => {
        // Update the social Element as per the mode
        form.validateFields().then(() => {
            setDisableForm(true);
            mutate({
                dataProviderName: DATAPROVIDER_SOCIAL,
                resource: RESOURCE_PATH.SOCIAL,
                id: socialElementId,
                values: { operation: "comment", comment: form.getFieldValue("comment") },
                successNotification: false,
                errorNotification: false
            },
                {
                    onSuccess: () => {
                        form.resetFields();
                        setDisableForm(false);
                    },
                    onError: () => {
                        message.error("Unable to post your comment. Please try again later.");
                        setDisableForm(false);
                    }
                }
            );
        }).catch((errorInfo) => {
            setDisableForm(false);
        });
    }
    return (
        <>
            <Form disabled={disableForm} layout="vertical" form={form} autoComplete="off">
                <Form.Item
                    name="comment"
                    label="Comment"
                    tooltip="Post your thoughts on this article"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            min: 15,
                            max: 300
                        }
                    ]}
                >
                    <Input.TextArea rows={3} placeholder="Leave your thoughts here..." />
                </Form.Item>
                <Form.Item style={{ textAlign: "right" }}>
                    <Button
                        type="primary"
                        onClick={() => { handleComment() }}
                    >
                        Post
                    </Button>
                </Form.Item>
            </Form>
            <Divider></Divider>
            {
                comments && arrayExtensions.validateArray(comments) &&
                <AntdList
                    header={<Typography.Title level={5}>Comments</Typography.Title>}
                    itemLayout="horizontal"
                    bordered
                    dataSource={comments.sort((a: IComment, b: IComment) => { return dayjs(b.date).isAfter(dayjs(a.date)) ? 1 : -1 })}
                    pagination={{ position:"bottom", align: "end", pageSize: 5 }}
                    renderItem={(item: IComment, index) => (
                        <AntdList.Item>
                            <AntdList.Item.Meta
                                title={
                                    <Space split={<Divider type="vertical" />}>
                                        <ShowUserCard id={item.by} key={`cmnt-${index}`} />
                                        {displayRelativeDate(item.date)}
                                    </Space>
                                }
                                description={item.body}
                            />
                        </AntdList.Item>

                    )}
                />

            }
        </>
    );
}